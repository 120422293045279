import ROUTES_ARRAY from "../constants/routes.constants";
import configSelector from "common/store/selectors/configSelector";
import pageSelector from "common/store/selectors/pageSelector";
import { store } from "common/store/index";

const config = store.getState().config || {};
let subPath = configSelector.getSubPath({ config });
let landingScreenConfig = pageSelector.getLandingPage({ config });
let rootRoute = "";
if (subPath) {
  rootRoute = "/" + subPath;
}

export function initialiseScreenHelper(config) {
  subPath = configSelector.getSubPath({ config });
  landingScreenConfig = pageSelector.getLandingPage({ config });
  if (subPath) {
    rootRoute = "/" + subPath;
  }
}

class ScreenHelper {
  static isMenuScreen(customPages, pathname) {
    const customRoutes = (customPages || []).map((page) => page.path);
    let allRoutesExceptMenuRoute = [...customRoutes, ...ROUTES_ARRAY];
    let isMenuScreenUrl = true;

    for (let i = 0; i < allRoutesExceptMenuRoute.length; i++) {
      if (pathname.startsWith(rootRoute + allRoutesExceptMenuRoute[i])) {
        isMenuScreenUrl = false;
        break;
      }
    }

    /**
     * Makes sure menu in navigator is not highlighted on landing screen
     */
    if (this.isLandingScreen(pathname)) {
      isMenuScreenUrl = false;
    }

    return isMenuScreenUrl;
  }

  static isLandingScreen(pathname) {
    if (landingScreenConfig?.enabled) {
      pathname = pathname.replace(/landing-page-demo\/.*/g, "");
      if (subPath) {
        if (pathname.replace(/\//g, "") === subPath) {
          return true;
        }
      } else {
        if (pathname === "/") {
          return true;
        }
      }
    }
    return false;
  }

  static isItemDetailsScreen(pathname) {
    return pathname.startsWith(rootRoute + "/item-list");
  }

  static isFavouritesScreen(pathname) {
    return pathname.startsWith(rootRoute + "/favourites");
  }

  static isPastOrdersScreen(pathname) {
    return pathname.startsWith(rootRoute + "/past-orders");
  }

  static isCartScreen(pathname) {
    return pathname.startsWith(rootRoute + "/cart");
  }

  static isCheckoutScreen(pathname) {
    return pathname.startsWith(rootRoute + "/checkout");
  }

  static isCouponsScreen(pathname) {
    return pathname.startsWith(rootRoute + "/coupons");
  }

  static isReferralScreen(pathname) {
    return pathname.startsWith(rootRoute + "/by_ref");
  }

  static isWalletScreen(pathname) {
    return pathname.startsWith(rootRoute + "/wallet");
  }

  static isReferAndEarnScreen(pathname) {
    return pathname.startsWith(rootRoute + "/refer-and-earn");
  }

  static isProfileScreen(pathname) {
    return pathname.startsWith(rootRoute + "/profile");
  }

  static isOrderSuccessScreen(pathname) {
    return pathname.startsWith(rootRoute + "/order-success");
  }

  static isCustomScreen(pathname, customPath) {
    return pathname.startsWith(rootRoute + customPath);
  }
}

export default ScreenHelper;
