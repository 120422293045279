import React from "react";
export default function AppleIcon({ fill }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5261 16.1314C12.8003 16.835 12.0078 16.7239 11.2449 16.3906C10.4376 16.0499 9.69697 16.0351 8.84523 16.3906C7.7787 16.8498 7.21581 16.7165 6.57885 16.1314C2.96449 12.4059 3.49776 6.73258 7.60094 6.5252C8.60082 6.57704 9.29703 7.07328 9.88214 7.11771C10.7561 6.93996 11.593 6.42891 12.5262 6.49557C13.6446 6.58445 14.489 7.02884 15.0444 7.82874C12.7336 9.21375 13.2817 12.2578 15.4 13.1096C14.9778 14.2205 14.4297 15.3241 13.5187 16.1388L13.5261 16.1314ZM9.80807 6.48076C9.69697 4.82912 11.0375 3.46632 12.5781 3.33301C12.7929 5.24388 10.845 6.66592 9.80807 6.48076Z"
        fill={fill || "black"}
      />
    </svg>
  );
}
