import React from "react";
import { matchPath } from "react-router-dom";
import configSelector from "common/store/selectors/configSelector";

// shimmers
import CartShimmer from "../views/cart/shimmer-view/index.component";
import CheckoutShimmer from "../views/checkout/shimmer-view/index.component";
import CouponsShimmer from "../views/coupons/shimmer-view/index.component";
import CustomPageShimmer from "../views/custom-page/shimmer-view/index.component";
import ExploreShimmer from "../views/explore/shimmer-view/index.component";
import FavouritesShimmer from "../views/favorites/shimmer-view/index.component";
import OrderSuccessShimmer from "../views/order-success/shimmer-view/index.component";
import OrdersShimmer from "../views/orders/shimmer-view/index.component";
import PageNotFoundShimmer from "../views/page-not-found/shimmer-view/index.component";
import ProfileShimmer from "../views/profile/shimmer-view/index.component";
import ReferAndEarnShimmer from "../views/refer-and-earn/shimmer-view/index.component";
import StoreLocatorShimmer from "../views/store-locator/shimmer-view/index.component";
import WalletShimmer from "../views/wallet/shimmer-view/index.component";
import ItemDetailsShimmer from "./../views/item-details/shimmer-view/index.component";

const getCurrentFallbackHandler = (
  currentPath,
  allRoutes,
  setCurrentFallback,
  menuRoute,
  config
) => {
  const customRoutesArray = [];
  const subPath = configSelector.getSubPath({ config });
  const rootRoute = subPath.trim() ? "/" + subPath : "";
  allRoutes.forEach((page) => customRoutesArray.push(page.path));

  /**
   * if current path is a match returns an object with details else
   * returns null
   *
   * @param {String || Array<string>} path
   * @returns null || object
   */
  const checkExistence = (path) => {
    const isMatch = matchPath(currentPath, {
      path: rootRoute + path,
    });

    return isMatch;
  };

  if (checkExistence("/item-list")) {
    return setCurrentFallback(<ItemDetailsShimmer />);
  } else if (checkExistence("/store-locator")) {
    return setCurrentFallback(<StoreLocatorShimmer />);
  } else if (checkExistence("/cart")) {
    return setCurrentFallback(<CartShimmer />);
  } else if (checkExistence("/checkout")) {
    return setCurrentFallback(<CheckoutShimmer />);
  } else if (checkExistence("/past-orders")) {
    return setCurrentFallback(<OrdersShimmer />);
  } else if (checkExistence("/favourites")) {
    return setCurrentFallback(<FavouritesShimmer />);
  } else if (checkExistence("/wallet")) {
    return setCurrentFallback(<WalletShimmer />);
  } else if (checkExistence("/profile")) {
    return setCurrentFallback(<ProfileShimmer />);
  } else if (checkExistence("/coupons")) {
    return setCurrentFallback(<CouponsShimmer />);
  } else if (checkExistence("/refer-and-earn")) {
    return setCurrentFallback(<ReferAndEarnShimmer />);
  } else if (checkExistence("/order-success")) {
    return setCurrentFallback(<OrderSuccessShimmer />);
  } else if (checkExistence(menuRoute)) {
    return setCurrentFallback(<ExploreShimmer />);
  } else if (checkExistence(customRoutesArray)) {
    return setCurrentFallback(<CustomPageShimmer />);
  } else {
    return setCurrentFallback(<PageNotFoundShimmer />);
  }
};

export default getCurrentFallbackHandler;
