import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat.js";

dayjs.extend(customParseFormat);

const defaultStore = {
  id: null,
  name: "",
  address: null,
  city: null,
  status: "",
  lat: null,
  lng: null,
  phone: null,
  open_hours: null,
  temporarily_closed: null,
  closing_day: null,
  fulfillment_modes: null,
  opening_time: null,
  closing_time: null,
  delivery_min_offset_time: null,
  pickup_min_offset_time: null,
  enabled_for_ordering: null,
  time_slots: null,
  delivery_distance: null,
  min_order_total: null,
  raw_data: null,
  features: {},
};

export default class Store {
  constructor(store = defaultStore) {
    this.biz_location_id = store.biz_location_id;
    this.id = store.biz_location_id;
    this.name = store.name;
    this.address = store.address;
    this.city = store.city;
    this.status = store.status;
    this.lat = store.lat || 0;
    this.lng = store.lng || 0;
    this.phone = store.phone;
    this.open_hours =
      dayjs(store.opening_time, "hh:mm:ss").format("HH:mm") +
      " - " +
      dayjs(store.closing_time, "hh:mm:ss").format("HH:mm");
    this.temporarily_closed = store.temporarily_closed || false;
    this.closing_day = store.closing_day || false;
    this.closed = store.temporarily_closed || store.closing_day;
    this.fulfillment_modes = store.fulfillment_modes;
    this.opening_time = store.opening_time;
    this.closing_time = store.closing_time;
    this.close_msg = store.close_msg;
    this.delivery_min_offset_time = store.delivery_min_offset_time;
    this.pickup_min_offset_time = store.pickup_min_offset_time;
    this.enabled_for_ordering = store.enabled_for_ordering;
    if (store.time_slots && store.time_slots.length > 0) {
      this.time_slots = store.time_slots.sort((a, b) => {
        return a.start_time > b.start_time;
      });
    }
    this.delivery_distance = store.delivery_distance;
    this.min_order_total = store.min_order_total;
    this.raw_data = store;
    this.distanceToUser = (lat, lng) =>
      distance(lat, lng, this.lat, this.lng, "K");
    this.delivery_distance_km = getStoreDistanceInKillometer(
      store.delivery_distance
    );
    this.features = store.features;
  }
}

function getStoreDistanceInKillometer(distanceInMeter) {
  if (distanceInMeter) {
    const killomenters = distanceInMeter / 1000;
    return `(${killomenters.toFixed(1)} km)`;
  }
  return "";
}

function distance(lat1, lon1, lat2, lon2, unit) {
  if (lat1 === lat2 && lon1 === lon2) {
    return 0;
  } else {
    let radlat1 = (Math.PI * lat1) / 180;
    let radlat2 = (Math.PI * lat2) / 180;
    let theta = lon1 - lon2;
    let radtheta = (Math.PI * theta) / 180;
    let dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit === "K") {
      dist = dist * 1.609344;
    }
    if (unit === "N") {
      dist = dist * 0.8684;
    }
    return dist;
  }
}
