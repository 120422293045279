import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";

import { PersistGate } from "redux-persist/lib/integration/react";
import GTMService from "common/services/GTMService";
import configService from "common/services/configService";
import freshChatService from "common/services/freshChat";
import googlePlacesService from "common/services/googlePlaces";
import upsdkService from "common/services/upsdkService";
import { persistor, store } from "common/store";
import configSelector from "common/store/selectors/configSelector";
import App from "./app";
import GenericError from "./common/something-went-wrong/index.component";
import { setDefaults } from "./helpers/helmetHelper";
import i18n from "./i18n/index";
import reportWebVitals from "./reportWebVitals";
import LanguageHelper from "common/helpers/language.helper";
import loadPolyfills from "./helpers/polyfills";
import dayjs from "dayjs";
import { hotjar } from "react-hotjar";
import InvalidQrScanned from "./views/invalid-qr/index.component";
import LocalStorageHelper from "common/helpers/localStorage.helper";

import {
  ORDERING_TYPE_ONLINE,
  ORDERING_TYPE_QR,
} from "./constants/enums.constants";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import "@urbanpiper-engineering/meraki-components/dist/main.css";
import "./styles/index.scss";
import GAService from "common/services/GA4Service";
import GTMga4Service from "common/services/GTMga4Service";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://65b81bf9464d490690151ae2f6087de7@o16291.ingest.sentry.io/6268340",
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: "production",
    ignoreErrors: [
      "nonpromise_rejections",
      /^Non-Error promise rejection/,
      /^Request failed with status code 40/,
      /^Cannot return null for non-nullable field/,
    ],
  });
}

let isConfigAvailable = false;
let isStateAvailable = LocalStorageHelper.getQrModeState();
let orderingType = ORDERING_TYPE_ONLINE;
let isWebsiteValid = false;

if (window) {
  if (window.ROOT_CONFIG) {
    isConfigAvailable = true;
  }

  if (isConfigAvailable && window.ROOT_CONFIG?.biz?.mode === ORDERING_TYPE_QR) {
    orderingType = ORDERING_TYPE_QR;
  }

  if (isConfigAvailable) {
    /**
     * The InvalidQrScanned will be rendered only for invalid QR Code Menus
     * There is no scope of config missing in online ordering mode
     */
    if (orderingType === ORDERING_TYPE_QR) {
      isWebsiteValid = isStateAvailable;
    } else {
      isWebsiteValid = true;
    }
  }
}

let SDK_LOADED = false;
let events = ["mousedown", "mousemove", "keypress", "scroll", "touchstart"];

function initiateSDKS() {
  if (SDK_LOADED) {
    return;
  }

  SDK_LOADED = true;

  events.forEach(function (name) {
    document.removeEventListener(name, initiateSDKS, true);
  });

  let config = window.ROOT_CONFIG;
  let mapsKey = configSelector.getGoogleMapsKey({ config: config });
  const freshChat = configSelector.getFreshChat({ config: config });
  const secondayColor = configSelector.getSecondaryColor({ config: config });
  const gtm = configSelector.getGTM({ config: config });
  const ga = configSelector.getGA({ config });
  const hotjarConfig = configSelector.getHotjar({ config: config });

  if (hotjarConfig && hotjarConfig.enabled) {
    hotjar.initialize(hotjarConfig.hjid, hotjarConfig.hjsv);
  }

  googlePlacesService.init({
    apiKey: mapsKey,
    callback: () => {
      return;
    },
  });

  if (freshChat?.enable && freshChat?.token?.length) {
    freshChatService.init({
      token: freshChat.token,
      backgroundColor: secondayColor,
    });
  }

  if (gtm?.enable && gtm?.id?.length) {
    if (gtm?.ga4Enable) {
      GTMga4Service.init({ gtmId: gtm?.id });
    } else {
      GTMService.init({ gtmId: gtm?.id });
    }
  }

  if (ga?.enable && ga?.id?.length) {
    GAService.init({ gaId: ga?.id });
  }
}

async function fireWhenConfigIsReady() {
  if (typeof window.ROOT_CONFIG != "undefined") {
    await loadPolyfills();
    configService.init(window.ROOT_CONFIG);

    upsdkService.init({
      BIZ_ID: window.ROOT_CONFIG.biz.id,
      USERNAME: window.ROOT_CONFIG.biz.username,
      API_KEY: window.ROOT_CONFIG.biz.apiKey,
      ENV: window.ROOT_CONFIG.biz.env || "production",
      captchaKey: `6LefjhcnAAAAADGL9_ZcwQAFcBtwsZY2E1gD_7He`,
    });

    window.addEventListener("load", () => {
      events.forEach(function (name) {
        document.addEventListener(name, initiateSDKS, true);
      });
    });

    // set favicon
    const favicon = configSelector.getFavicon({ config: window.ROOT_CONFIG });
    setDefaults({ favicon });
    /**
     * Initialize moment with active language
     */
    const activeLanguage = LanguageHelper.getLanguage(window.ROOT_CONFIG);
    dayjs.locale(activeLanguage);
  }
}

(async () => {
  await fireWhenConfigIsReady();

  ReactDOM.render(
    <Sentry.ErrorBoundary fallback={GenericError()}>
      <Suspense fallback="">
        <I18nextProvider i18n={i18n}>
          <Provider store={store}>
            <PersistGate persistor={persistor}>
              {isWebsiteValid ? (
                <App orderingType={orderingType} />
              ) : (
                <InvalidQrScanned />
              )}
            </PersistGate>
          </Provider>
        </I18nextProvider>
      </Suspense>
    </Sentry.ErrorBoundary>,
    document.getElementById("root")
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
})();
