import React from "react";

export default function SearchLocationIcon({ fill, size, className }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={className}
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Location" fill={fill} fillRule="nonzero">
          <path
            d="M1.26591454,10.8 C1.81929312,5.79406007 5.79406007,1.81929312 10.8,1.26591454 L10.8,0 L13.2,0 L13.2,1.26591454 C18.2059399,1.81929312 22.1807069,5.79406007 22.7340854,10.8 L24,10.8 L24,13.2 L22.7340854,13.2 C22.1807069,18.2059399 18.2059399,22.1807069 13.2,22.7340854 L13.2,24 L10.8,24 L10.8,22.7340854 C5.79406007,22.1807069 1.81929312,18.2059399 1.26591454,13.2 L0,13.2 L0,10.8 L1.26591454,10.8 Z M3.68506663,10.8 L4.8,10.8 L4.8,13.2 L3.68506663,13.2 C4.21128194,16.8788294 7.12117061,19.7887181 10.8,20.3149333 L10.8,19.2 L13.2,19.2 L13.2,20.3149333 C16.8788294,19.7887181 19.7887181,16.8788294 20.3149333,13.2 L19.2,13.2 L19.2,10.8 L20.3149333,10.8 C19.7887181,7.12117061 16.8788294,4.21128194 13.2,3.68506663 L13.2,4.8 L10.8,4.8 L10.8,3.68506663 C7.12117061,4.21128194 4.21128194,7.12117061 3.68506663,10.8 Z M12,14.4 C13.3254834,14.4 14.4,13.3254834 14.4,12 C14.4,10.6745166 13.3254834,9.6 12,9.6 C10.6745166,9.6 9.6,10.6745166 9.6,12 C9.6,13.3254834 10.6745166,14.4 12,14.4 Z M12,16.8 C9.3490332,16.8 7.2,14.6509668 7.2,12 C7.2,9.3490332 9.3490332,7.2 12,7.2 C14.6509668,7.2 16.8,9.3490332 16.8,12 C16.8,14.6509668 14.6509668,16.8 12,16.8 Z"
            id="path-2"
          ></path>
        </g>
      </g>
    </svg>
  );
}
