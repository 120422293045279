import { useEffect } from "react";
import { withRouter } from "react-router-dom";
import ScreenHelper from "../helpers/screen.helper";

const ScrollToTop = ({
  children,
  customRoutes,
  landingScreenEnabled,
  location: { pathname },
}) => {
  const isMenuScreenUrl = ScreenHelper.isMenuScreen(
    customRoutes,
    pathname,
    landingScreenEnabled
  );

  useEffect(() => {
    if (!isMenuScreenUrl) {
      window.scrollTo({
        top: 0,
        left: 0,
      });
    }
  }, [pathname, isMenuScreenUrl]);

  return children || null;
};

export default withRouter(ScrollToTop);
