import {
  DELIVER_DATE_TIME,
  SELECTED_SLOT,
  DELIVERY_TYPE,
  RESET_DELIVERY_DETAILS,
} from "../actions/deliveryTime";
import deepClone from "common/helpers/deepClone";
const initialState = {
  delivery: {
    deliveryTime: null,
    selectedTimeSlot: null,
    selectedType: "deliver_now",
  },
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case DELIVER_DATE_TIME: {
      const data = action.data;
      const delivery = state.delivery;
      delivery.deliveryTime = data.valueOf();
      return {
        ...state,
        delivery: deepClone(delivery),
      };
    }
    case SELECTED_SLOT: {
      const data = action.data;
      const delivery = state.delivery;
      delivery.selectedTimeSlot = data;
      return {
        ...state,
        delivery: deepClone(delivery),
      };
    }
    case DELIVERY_TYPE: {
      const data = action.data;
      const delivery = state.delivery;
      delivery.selectedType = data;
      return {
        ...state,
        delivery: deepClone(delivery),
      };
    }
    case RESET_DELIVERY_DETAILS: {
      const delivery = {
        deliveryTime: null,
        selectedTimeSlot: null,
        selectedType: "deliver_now",
      };

      return {
        ...state,
        delivery: delivery,
      };
    }

    default:
      return state;
  }
};
