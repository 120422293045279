import React, { useRef, useEffect, useState, forwardRef } from "react";

import {
  Slide,
  Dialog,
  FormGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";

import ChevronGroupIcon from "../../assets/icons/chevron-group.icon";
import CrossIcon from "../../assets/icons/cross.icon";
import SortByIcon from "../../assets/icons/sortby.icon";
import AppButton from "../button/index.component";
import Typography from "../typography/index.component";
import {
  ACTIVE_RADIO_ICON,
  INACTIVE_RADIO_ICON,
} from "../../constants/image.constants";
import { useTranslation } from "react-i18next";

import "./index.component.scss";

export default function SortBy({
  isMobileView,
  clearSortByCallback,
  applySortByCallback,
  headerColorConfig,
  secondaryTextColor,
  options,
  sortBy,
  secondaryColor,
  isSearchExpanded,
  primaryColor,
}) {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(false);
  const sortByButton = useRef(null);
  const sortByDialog = useRef(null);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setValue(sortBy);
  }, [sortBy]);

  const handleChange = (row) => {
    if (value === row) {
      return;
    } else {
      setValue(row);
    }
  };

  const onApplySortBy = () => {
    applySortByCallback(value);
    handleClose();
  };

  const onClearSortBy = () => {
    clearSortByCallback();
    handleClose();
  };

  const handleClick = () => {
    const sortByButtonEle = sortByButton.current;
    const sortByDialogEle = sortByDialog.current;
    const screenWidth = window.innerWidth;
    //to make sure dialog doesn't get overflowed out of screen
    const offset = 40;

    if (isMobileView) {
      sortByDialogEle.style.left = `0px`;
    } else {
      if (i18n.dir() === "rtl") {
        const position =
          screenWidth - sortByButtonEle.getBoundingClientRect().right - offset;
        sortByDialogEle.style.right = `${position}px`;
      } else {
        const position = sortByButtonEle.getBoundingClientRect().left - offset;
        sortByDialogEle.style.left = `${position}px`;
      }
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (!options || !options.sort_by) {
    return null;
  }

  const priceLabel = t("common.priceLowToHigh");

  return (
    <div className="sort-by-filter-wrapper">
      <div
        ref={sortByButton}
        className="filter-button"
        onClick={handleClick}
        style={{ position: isSearchExpanded ? "static" : "relative" }}
      >
        <SortByIcon className="button-icon" fill={primaryColor} />
        {!!value && (
          <div
            className="filter-applied-indicator"
            style={{ backgroundColor: secondaryColor }}
          />
        )}
        <Typography
          className="filter-name"
          variant="h3"
          weight="regular"
          fontColor={headerColorConfig.textColor}
        >
          {t("common.sortBy")}
        </Typography>
        <ChevronGroupIcon
          fill={headerColorConfig.iconColor}
          className="chevron-icon"
        />
      </div>
      <Dialog
        ref={sortByDialog}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        classes={{
          root: "sort-by-filter-dialog-root",
          container: "sort-by-filter-dialog-container",
          paper: "sort-by-filter-dialog-paper",
        }}
        disableScrollLock={true}
      >
        <div className="filter-title-wrapper">
          <Typography
            variant="h2"
            weight="bold"
            className="filter-title"
            fontColor={secondaryColor}
          >
            {t("filter.sortBy")}
          </Typography>
          <CrossIcon
            fill="#A3A3A4"
            size={{ width: 16, heigth: 16 }}
            className="close-dialog-icon"
            onClickCallback={handleClose}
          />
        </div>
        <FormGroup column="true" className="filters">
          {options?.sort_by?.map((row, index) => (
            <FormControlLabel
              key={index}
              classes={{ root: "filter-container" }}
              checked={value === row}
              control={
                <Radio
                  name={row}
                  icon={
                    <img
                      src={INACTIVE_RADIO_ICON}
                      className="radio-icon"
                      alt="Inactive Radio Icon"
                    />
                  }
                  checkedIcon={
                    <img
                      src={ACTIVE_RADIO_ICON}
                      className="radio-icon"
                      alt="Active Radio Icon"
                    />
                  }
                  onChange={() => handleChange(row)}
                />
              }
              label={
                <Typography
                  variant="h2"
                  weight="regular"
                  fontColor={secondaryTextColor}
                >
                  {row === "Price" ? priceLabel : row}
                </Typography>
              }
            />
          ))}
        </FormGroup>
        <div className="sort-by-button-wrapper">
          <AppButton
            fullWidth
            variant="outlined"
            className="sort-by-action-button"
            buttonColor={primaryColor}
            onClickCallback={onClearSortBy}
          >
            <Typography variant="h3" weight="regular" fontColor={primaryColor}>
              {t("filter.clearAll")}
            </Typography>
          </AppButton>
          <AppButton
            fullWidth
            variant="contained"
            className="sort-by-action-button"
            buttonColor={primaryColor}
            onClickCallback={onApplySortBy}
          >
            <Typography variant="h3" weight="regular" fontColor="#FFFFFF">
              {t("filter.apply")}
            </Typography>
          </AppButton>
        </div>
      </Dialog>
    </div>
  );
}

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
