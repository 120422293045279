import React from "react";
export default function LinkedinIcon({
  fill,
  size,
  className,
  onClickCallback,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      className={className}
    >
      <g fill="none" fillRule="evenodd">
        <g fill="#646E83" fillRule="nonzero">
          <g>
            <g>
              <g>
                <path
                  d="M13.971 8.203v5.166h-2.994v-4.82c0-1.21-.433-2.037-1.517-2.037-.828 0-1.32.557-1.537 1.096-.079.192-.099.46-.099.73v5.03H4.828s.04-8.162 0-9.008h2.996v1.277l-.02.03h.02v-.03c.398-.613 1.108-1.488 2.7-1.488 1.97 0 3.447 1.287 3.447 4.054zM1.695.018C.67.018 0 .69 0 1.574c0 .865.65 1.557 1.655 1.557h.02c1.045 0 1.695-.692 1.695-1.557C3.35.69 2.72.018 1.695.018zM.178 13.368h2.994V4.36H.178v9.009z"
                  transform="translate(-691 -2017) translate(0 1976) translate(588 30) translate(103 11)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
