import ByRefAlreadySignedInIcon from "../../assets/icons/byRefAlreadySignedIn.icon";

import "./index.styles.scss";

function GenericErrorDialog() {
  return (
    <div className="generic-error-dialog-wrapper">
      <ByRefAlreadySignedInIcon size={{ width: 250, heigth: 150 }} />
      <h1>Something went wrong</h1>
      <h2>We are working on fixing the problem. Please try again later.</h2>
      <a href="/menu">
        <button
          fullWidth
          variant="contained"
          class="goHomeBtn"
          buttonColor={"#fff"}
        >
          <h3>Go to Home</h3>
        </button>
      </a>
    </div>
  );
}

export default GenericErrorDialog;
