import React from "react";

export default function MenuIcon({ fill, size, className, opacity }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      className={className}
    >
      <defs>
        <path id="99ci0knwla" d="M16 10v2H0v-2h16zm0-6v2H0V4h16z" />
      </defs>
      <g fill="none" fillRule="evenodd" opacity={opacity}>
        <g>
          <g>
            <g>
              <g>
                <g transform="translate(-280 -746) translate(0 729) translate(280 13) translate(0 4)">
                  <path d="M0 0H16V16H0z" />
                  <use fill={fill} xlinkHref="#99ci0knwla" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
