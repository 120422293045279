import React, { Fragment } from "react";

import Typography from "../typography/index.component";

import "./index.component.scss";

export default function Badge(props) {
  // props
  const { children, badgeContent, secondaryColor, style = {} } = props;

  return (
    <Fragment>
      {badgeContent && (
        <div
          className="badge-wrapper"
          style={{ backgroundColor: secondaryColor, ...style }}
        >
          <Typography variant="h3" weight="regular" fontColor="#FFFFFF">
            {badgeContent}
          </Typography>
        </div>
      )}
      {children}
    </Fragment>
  );
}
