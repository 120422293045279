import React from "react";

import ShoppingCartIcon from "../../assets/icons/shopping-cart.icon";
import CurrencyHelper from "common/helpers/currency.helper";
import Typography from "../typography/index.component";
import useRouteHook from "../../hooks/useRoute.hook";
import { useTranslation } from "react-i18next";

import "./index.component.scss";

export default function Cart({
  cart,
  backgroundColor,
  color,
  disabledColor,
  disabledIconColor,
}) {
  const { t } = useTranslation();
  const { historyPush } = useRouteHook();

  const handleCartClick = () => {
    setTimeout(() => {
      historyPush("/cart");
    });
  };

  if (cart?.items?.length) {
    return (
      <div
        className="view-cart-cta"
        onClick={handleCartClick}
        style={{
          backgroundColor: backgroundColor,
        }}
      >
        <ShoppingCartIcon className="cart-icon" fill={color} />
        <Typography
          variant="h3"
          weight="regular"
          className="view-cart-text"
          fontColor={color}
        >
          {t("header.viewCart")}
        </Typography>
        <Typography
          variant="h3"
          weight="regular"
          className="cart-sab-total"
          fontColor={color}
        >
          {` (${CurrencyHelper.format(cart.total)})`}
        </Typography>
      </div>
    );
  }

  return (
    <div
      className="cart-cta-wrapper"
      style={{
        backgroundColor: disabledColor,
      }}
    >
      <ShoppingCartIcon className="cart-icon" fill={disabledIconColor} />
    </div>
  );
}
