import { gql } from "@apollo/client";

const GET_QSR_STORE = gql`
  query getQsrStore {
    getCurrentStore {
      address
      biz_location_id
      city
      closing_day
      delivery_charge
      delivery_min_offset_time
      enabled_for_ordering
      fulfillment_modes
      hide_store_name
      lat
      lng
      merchant_ref_id
      min_order_total
      name
      on_close_msg
      on_select_msg
      packaging_charge
      phone
      pickup_min_offset_time
      sort_order
      tax_rate
      temporarily_closed
    }
  }
`;

const GET_LATEST_ANNOUNCEMENT = gql`
  query getLatestAnnouncement {
    getLatestAnnouncement {
      title
      description
      styles {
        color
      }
      url
    }
  }
`;

export default {
  GET_QSR_STORE,
  GET_LATEST_ANNOUNCEMENT,
};
