export default class LocalStorageHelper {
  /**
   * Returns language stored in localStorage
   *
   * @return {string|null} language stored in localStorage (Eg: 'en').
   */
  static getLanguageFromLocalStorage() {
    return localStorage.getItem("i18nextLng") || null;
  }

  static getQrModeState() {
    let state = null;
    if (window) {
      const url = new URL(window.location.href);
      state = url.searchParams.get("state");
      if (state) {
        localStorage.setItem("qr-mode-state", state);
      }
    }
    return state || localStorage.getItem("qr-mode-state");
  }
}
