import React, {
  useRef,
  useState,
  useCallback,
  useEffect,
  useContext,
  Fragment,
} from "react";

import {
  AppBar,
  TextField,
  InputAdornment,
  ClickAwayListener,
} from "@material-ui/core";
import debounce from "common/helpers/debounce";

import classNames from "classnames";
import {
  bright_50,
  bright_70,
  bright_90,
  increaseBrightness,
} from "common/helpers/adjustColor";
import {
  COLOR_BACKGROUND_GREY,
  COLOR_BLACK,
  COLOR_BURNT_UMBER,
  COLOR_NIGHT_RIDER,
  COLOR_SPUN_PEARL,
  COLOR_WHITE,
} from "../../constants/colors.constants";
import checkLightOrDark from "common/helpers/checkLightOrDark";
import configSelector from "common/store/selectors/configSelector";
import itemSelector from "common/store/selectors/itemSelector";
import pageSelector from "common/store/selectors/pageSelector";
import ScreenHelper from "../../helpers/screen.helper";
import { BaseContext } from "../../context/BaseContext";
import ChevronIcon from "../../assets/icons/chevron.icon";
import CrossIcon from "../../assets/icons/cross.icon";
import MenuIcon from "../../assets/icons/menu.icon";
import SearchIcon from "../../assets/icons/search.icon";
import Badge from "../badge/index.component";
import Cart from "../cart/index.component";
import FilterBy from "../filter-by/index.component";
import LocationSearch from "../location-search/index.component";
import ServicableErrorPrompt from "../servicable-error-prompt/index.component";
import SortBy from "../sort-by/index.component";
import SubHeader from "../sub-header/index.component";
import Typography from "../typography/index.component";
import TableBarIcon from "../../assets/icons/table-bar.icon";
import useRouteHook from "../../hooks/useRoute.hook";
import { useTranslation } from "react-i18next";
import AnouncementBar from "@urbanpiper-engineering/meraki-components/dist/Cells/AnouncementBar";

import "./index.component.scss";

export default function Header({
  filterBy,
  sortBy,
  userData,
  cart,
  isMobileView,
  secondaryTextColor,
  config,
  primaryTextColor,
  primaryColor,
  secondaryColor,
  toggleDrawerCallback,
  showAuthDialogCallback,
  fetchSearch,
  clearSearch,
  sortByCallback,
  filterByCallback,
  history,
  toggleChangePasswordCallback,
  setSearchActive,
  searchQuery,
  setSearchQuery,
  customRoutes,
  options,
  fulfilmentCallback,
  fulfilmentType,
  qrModeBizAddress,
  qrModeBizName,
  showStoreStatusRibbon,
}) {
  const [headerColors, setHeaderColors] = useState({});
  const [isDarkHeader, setDarkHeader] = useState(false);
  const [isSearchExpanded, setSearchExpandedView] = useState(false);
  const inputRef = useRef();
  const searchRef = useRef();
  const headerRef = useRef();
  const [appbarHeight, setAppBarHeight] = useState(0);
  const { historyPush, historyGoBack } = useRouteHook();
  const isTopbarEnabled =
    configSelector.getTopbar({ config })?.customizations?.enabled || false;

  // header
  const headerColorsConfig = configSelector.getHeaderColors({ config });
  const headerBackgroundColor =
    headerColorsConfig.backgroundColor || COLOR_WHITE;

  // variables
  const {
    landingScreenEnabled,
    sticky,
    isQrModeEnabled,
    isQsrEnabled,
    announcement,
  } = useContext(BaseContext);
  const bizName = configSelector.getBizName({ config });
  const bizLogoURL = configSelector.getBizLogoURL({ config });
  const squareLogoUrlMobile = configSelector.getBizSquareLogoURLMobile({
    config,
  });

  const cartPageConfig = pageSelector.getCartPage({ config });
  const totalItems =
    cart?.items?.reduce((acc, item) => acc + item.quantity, 0) || null;
  const pathname = history.location.pathname;

  const isCartScreen = ScreenHelper.isCartScreen(pathname);
  const isCheckoutScreen = ScreenHelper.isCheckoutScreen(pathname);
  const isExploreScreen = ScreenHelper.isMenuScreen(
    customRoutes,
    pathname,
    landingScreenEnabled
  );
  const isItemDetailsScreen = ScreenHelper.isItemDetailsScreen(pathname);
  const isCouponsScreen = ScreenHelper.isCouponsScreen(pathname);
  const isLandingScreen = ScreenHelper.isLandingScreen(pathname);

  const showSortWidget =
    itemSelector.showSortWidget({ config }) && isExploreScreen;
  const showFilterWidget =
    itemSelector.showFilterWidget({ config }) && isExploreScreen;
  const showSearchWidget =
    itemSelector.showSearchWidget({ config }) && isExploreScreen;
  const showGoBackCta =
    isItemDetailsScreen || isCartScreen || isCheckoutScreen || isCouponsScreen;
  const showLocationFulfilmentWidget =
    (isExploreScreen || isItemDetailsScreen) && !isQrModeEnabled;
  const showQrModeStoreName = isQrModeEnabled;
  const showCartWidget =
    cartPageConfig.enabled &&
    (isExploreScreen || isItemDetailsScreen) &&
    (isQrModeEnabled ? (isQsrEnabled ? true : false) : true);
  const showRightSection = isMobileView ? isExploreScreen : true;
  const lightOrDark = checkLightOrDark(headerBackgroundColor);

  useEffect(() => {
    if (lightOrDark === "light") {
      setDarkHeader(false);
      setHeaderColors(headerColorsConfig.colors.light);
    } else {
      setDarkHeader(true);
      setHeaderColors(headerColorsConfig.colors.dark);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lightOrDark]);

  useEffect(() => {
    if (headerRef.current) {
      setAppBarHeight(headerRef.current.getBoundingClientRect().height);
    }
  }, [
    announcement,
    headerRef,
    isMobileView,
    isTopbarEnabled,
    pathname,
    isLandingScreen,
  ]);

  const handleApplyFilter = (data) => {
    filterByCallback(data);
  };

  const handleApplySortBy = (type) => {
    sortByCallback(type);
  };

  const handleClearSortBy = () => {
    sortByCallback(null);
  };

  const handleClearAllFilters = () => {
    filterByCallback([]);
  };

  const handleSearchIconClick = () => {
    const searchInput = searchRef?.current;
    if (searchInput && !isMobileView) {
      searchInput.style.position = "absolute";
      searchInput.style.width = "100%";
      searchInput.style.transition = "width 0.5s";
    }

    setSearchExpandedView(true);

    if ((filterBy && filterBy.length) || sortBy) {
      filterByCallback([]);
      sortByCallback(null);
    }
  };

  const shrinkSearchBar = () => {
    const searchInput = searchRef?.current;
    if (searchInput) {
      searchInput.style.width = "40px";
      searchInput.style.transition = "width 0.5s";
      setSearchExpandedView(false);
    }
  };

  const handleClearSearchIconClick = (event) => {
    event.stopPropagation();
    if (searchQuery) {
      setSearchQuery("");
      clearSearch();
    }
    shrinkSearchBar();
  };

  const handleOnChange = (event) => {
    setSearchQuery(event.target.value);
    debounceCall(event.target.value, fulfilmentType);
    setSearchActive(true);
  };

  const handleClickAway = useCallback(() => {
    if (isMobileView) return;
    shrinkSearchBar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearSearch, isMobileView]);

  const handleHamburgerIconClick = () => {
    toggleDrawerCallback();
  };

  const handleGoBackClick = () => {
    historyGoBack();
  };

  const handleBrandLogoClick = () => {
    sessionStorage.setItem("scrolledSoFar", 0);
    historyPush("");
    setSearchActive(false);
    setSearchQuery("");
    if (!isQrModeEnabled) {
      clearSearch();
    }
  };

  //doesn't work as desired with debounce
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceCall = useCallback(
    debounce((_searchVal, fulfillmentType) => {
      if (!_searchVal) {
        clearSearch();
      }

      fetchSearch(
        _searchVal,
        isQrModeEnabled ? null : fulfillmentType,
        sortBy,
        filterBy
      );
    }, 1000),
    [fulfilmentType, isQrModeEnabled]
  );

  const handleEscKeyPress = useCallback(
    (event) => {
      if (event.keyCode === 27) {
        handleClickAway();
      }
    },
    [handleClickAway]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleEscKeyPress, false);
    return () => {
      document.removeEventListener("keydown", handleEscKeyPress, false);
    };
  }, [handleEscKeyPress]);

  useEffect(() => {
    if (inputRef && inputRef.current && !isMobileView) {
      const timeout = setTimeout(() => {
        if (inputRef && inputRef.current) {
          inputRef.current.focus();
        }
      }, 100);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [isSearchExpanded, isMobileView]);

  if (isLandingScreen && !isQrModeEnabled) {
    return isTopbarEnabled ? (
      <Fragment>
        <div
          style={{
            height: appbarHeight,
          }}
          className="landing-header-placeholder"
        ></div>
        <AppBar
          className={classNames(
            "header-box-shadow",
            "header-wrapper",
            "x-js-header"
          )}
          position="fixed"
          ref={headerRef}
        >
          {announcement?.description ? (
            <div id="announcement-bar-xyz">
              <AnouncementBar
                announcement={announcement.description || ""}
                url={announcement?.url || ""}
                backgroundColor={announcement?.styles?.color || "#000"}
              />
            </div>
          ) : null}
          <SubHeader
            showAuthDialogCallback={showAuthDialogCallback}
            primaryColor={primaryColor}
            primaryTextColor={primaryTextColor}
            secondaryTextColor={secondaryTextColor}
            userData={userData}
            toggleChangePasswordCallback={toggleChangePasswordCallback}
          />
        </AppBar>
      </Fragment>
    ) : null;
  }
  return (
    <Fragment>
      <div
        className="general-header-placeholder"
        style={{
          height: appbarHeight,
        }}
      >
        {/*
          To aquire the vacant space of header in the HTML flow
        */}
      </div>

      <AppBar
        elevation={0}
        className={classNames("header-wrapper", "x-js-header", {
          "header-box-shadow": !sticky.isSticky,
        })}
        position="fixed"
        ref={headerRef}
      >
        {isExploreScreen && announcement?.description ? (
          <div id="announcement-bar-xyz">
            <AnouncementBar
              announcement={announcement.description || ""}
              url={announcement?.url || ""}
              backgroundColor={announcement?.styles?.color || "#000"}
            />
          </div>
        ) : null}

        {showStoreStatusRibbon && <StoreStatusRibbon />}

        {!isQrModeEnabled && isTopbarEnabled && (
          <SubHeader
            showAuthDialogCallback={showAuthDialogCallback}
            primaryColor={primaryColor}
            primaryTextColor={primaryTextColor}
            secondaryTextColor={secondaryTextColor}
            userData={userData}
            toggleChangePasswordCallback={toggleChangePasswordCallback}
          />
        )}

        <div
          className="main-header"
          style={{ backgroundColor: headerBackgroundColor }}
        >
          <div className="header-container container">
            <div className="left-section">
              <div className="header-cta-wrapper">
                {showGoBackCta ? (
                  <BackIconCTA
                    handleGoBackClick={handleGoBackClick}
                    isDarkHeader={isDarkHeader}
                    headerBackgroundColor={headerBackgroundColor}
                  />
                ) : (
                  !isQrModeEnabled && (
                    <HamburgerIconCTA
                      isDarkHeader={isDarkHeader}
                      headerBackgroundColor={headerBackgroundColor}
                      handleHamburgerIconClick={handleHamburgerIconClick}
                    />
                  )
                )}
              </div>
              <div
                className="brand-name-wrapper"
                onClick={handleBrandLogoClick}
              >
                <img
                  src={
                    isMobileView
                      ? squareLogoUrlMobile || bizLogoURL
                      : bizLogoURL
                  }
                  alt={bizName}
                  className="brand-logo"
                />
              </div>

              {showLocationFulfilmentWidget && (
                <LocationSearch
                  fulfilmentCallback={fulfilmentCallback}
                  isMobileView={isMobileView}
                  secondaryColor={secondaryColor}
                  secondaryTextColor={secondaryTextColor}
                  userData={userData}
                  isDarkHeader={isDarkHeader}
                  headerColorConfig={headerColors}
                />
              )}

              {showQrModeStoreName && (
                <div className="qr-code-store-address">
                  <div className="qr-code-store-welcome">
                    <Typography
                      className="qr-code-store-address-text"
                      variant="h2"
                      weight="semiBold"
                      fontColor={headerColors.textColor}
                    >
                      {qrModeBizName}
                    </Typography>
                  </div>

                  <Typography
                    className="qr-code-store-address-text"
                    variant="h3"
                    weight="semiBold"
                    fontColor={headerColors.textColor}
                  >
                    {qrModeBizAddress}
                  </Typography>
                </div>
              )}

              {/* for future use */}
              {false && isQrModeEnabled && isMobileView && (
                <QrModeTableInfo primaryColor={primaryColor} />
              )}
            </div>

            {showRightSection && (
              <div className="right-section">
                <div className="sort-filter-search-wrapper">
                  {showSortWidget && (
                    <SortBy
                      options={options}
                      sortBy={sortBy}
                      primaryColor={primaryColor}
                      isMobileView={isMobileView}
                      secondaryColor={secondaryColor}
                      primaryTextColor={primaryTextColor}
                      isSearchExpanded={isSearchExpanded}
                      headerColorConfig={headerColors}
                      showFilterWidget={showFilterWidget}
                      secondaryTextColor={secondaryTextColor}
                      applySortByCallback={handleApplySortBy}
                      clearSortByCallback={handleClearSortBy}
                    />
                  )}

                  {showFilterWidget && (
                    <FilterBy
                      secondaryColor={secondaryColor}
                      options={options}
                      filterBy={filterBy}
                      isMobileView={isMobileView}
                      primaryColor={primaryColor}
                      isSearchExpanded={isSearchExpanded}
                      primaryTextColor={primaryTextColor}
                      secondaryTextColor={secondaryTextColor}
                      headerColorConfig={headerColors}
                      applyFilterCallback={handleApplyFilter}
                      clearAllFiltersCallback={handleClearAllFilters}
                    />
                  )}

                  {showSearchWidget && (
                    <SearchBar
                      ref={{ searchRef, inputRef }}
                      handleSearchIconClick={handleSearchIconClick}
                      handleClickAway={handleClickAway}
                      searchQuery={searchQuery}
                      isSearchExpanded={isSearchExpanded}
                      handleOnChange={handleOnChange}
                      handleClearSearchIconClick={handleClearSearchIconClick}
                    />
                  )}
                </div>

                {showCartWidget && (
                  <CartButtonCTA
                    totalItems={totalItems}
                    secondaryColor={secondaryColor}
                    isDarkHeader={isDarkHeader}
                    headerBackgroundColor={headerBackgroundColor}
                    primaryColor={primaryColor}
                    cart={cart}
                  />
                )}

                {/* for future use */}
                {false && isQrModeEnabled && !isMobileView && (
                  <QrModeTableInfo primaryColor={primaryColor} />
                )}
              </div>
            )}
          </div>
        </div>

        <ServicableErrorPrompt primaryColor={primaryColor} />
      </AppBar>
    </Fragment>
  );
}

/**
 * Components
 */

const SearchBar = React.forwardRef(
  (
    {
      handleSearchIconClick,
      handleClickAway,
      searchQuery,
      isSearchExpanded,
      handleOnChange,
      handleClearSearchIconClick,
    },
    { inputRef, searchRef }
  ) => {
    const { t, i18n } = useTranslation();
    return (
      <div
        className="search-bar-wrapper"
        ref={searchRef}
        onClick={handleSearchIconClick}
        style={i18n.dir() === "rtl" ? { left: 0 } : { right: 0 }}
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <TextField
            fullwidth="true"
            variant="filled"
            inputRef={inputRef}
            value={searchQuery}
            disabled={!isSearchExpanded}
            classes={{ root: "search-bar" }}
            placeholder={t("header.searchBarPlaceholder")}
            onChange={handleOnChange}
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon className="search-bar-icon" fill={COLOR_BLACK} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment
                  position="end"
                  onClick={handleClearSearchIconClick}
                >
                  {isSearchExpanded && (
                    <CrossIcon
                      fill={COLOR_BURNT_UMBER}
                      className="cross-icon"
                      size={{ width: 8, heigth: 8 }}
                    />
                  )}
                </InputAdornment>
              ),
            }}
          />
        </ClickAwayListener>
      </div>
    );
  }
);

const HamburgerIconCTA = ({
  isDarkHeader,
  headerBackgroundColor,
  handleHamburgerIconClick,
}) => (
  <div
    className="menu-cta"
    aria-label="menu"
    style={{
      backgroundColor: isDarkHeader
        ? increaseBrightness(headerBackgroundColor, bright_70)
        : COLOR_BACKGROUND_GREY,
    }}
    onClick={handleHamburgerIconClick}
  >
    <MenuIcon opacity="1" fill={COLOR_BLACK} className="hamburger-menu" />
  </div>
);

const BackIconCTA = ({
  handleGoBackClick,
  isDarkHeader,
  headerBackgroundColor,
}) => {
  const { i18n } = useTranslation();
  return (
    <div
      className="back-cta"
      onClick={handleGoBackClick}
      style={{
        backgroundColor: isDarkHeader
          ? increaseBrightness(headerBackgroundColor, bright_70)
          : COLOR_BACKGROUND_GREY,
      }}
    >
      <ChevronIcon
        fill={COLOR_NIGHT_RIDER}
        className="back-button-icon"
        size={{
          width: 16,
          heigth: 16,
        }}
        rotate={i18n.dir() === "rtl" ? "270deg" : "90deg"}
      />
    </div>
  );
};

const CartButtonCTA = ({
  cart,
  totalItems,
  secondaryColor,
  isDarkHeader,
  headerBackgroundColor,
  primaryColor,
}) => {
  return (
    <div className="add-to-cart-wrapper">
      <Badge badgeContent={totalItems} secondaryColor={secondaryColor}>
        <Cart
          cart={cart}
          disabledColor={
            isDarkHeader
              ? increaseBrightness(headerBackgroundColor, bright_50)
              : COLOR_SPUN_PEARL
          }
          disabledIconColor={
            isDarkHeader
              ? increaseBrightness(headerBackgroundColor, bright_90)
              : increaseBrightness(COLOR_SPUN_PEARL, bright_90)
          }
          backgroundColor={isDarkHeader ? COLOR_WHITE : primaryColor}
          color={isDarkHeader ? primaryColor : COLOR_WHITE}
        />
      </Badge>
    </div>
  );
};

const QrModeTableInfo = ({ primaryColor }) => {
  return (
    <div
      className="qr-mode-table-info"
      style={{
        backgroundColor: increaseBrightness(primaryColor, bright_90),
      }}
    >
      <TableBarIcon fill={primaryColor} />
      <Typography
        className="qr-code-store-address-text"
        variant="h3"
        weight="semiBold"
        fontColor={primaryColor}
      >
        A1
      </Typography>
    </div>
  );
};

const StoreStatusRibbon = () => {
  const { t } = useTranslation();
  return (
    <div className="store-status-ribbon">
      <Typography
        className="store-status-ribbon-text"
        variant="h3"
        weight="semiBold"
        fontColor={COLOR_BLACK}
      >
        {t("header.storeClosedMessage")}
      </Typography>
    </div>
  );
};
