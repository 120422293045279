function getLikes({ likes, catalogue }) {
  if (
    !likes ||
    !likes.data ||
    !catalogue ||
    !catalogue.data ||
    !catalogue.data.items
  ) {
    return [];
  }

  const itemList = [];
  const likedData = likes.data;

  likedData.forEach((row) => {
    let item = catalogue?.data?.items?.find(
      (catalogueItem) => catalogueItem?.id === row?.item?.id
    );
    if (item) {
      itemList.push(item);
    }
  });

  return itemList;
}

function isLiked({ likes, item_id }) {
  if (!likes || !item_id) {
    return false;
  }
  return likes.find((row) => row?.item?.id === item_id);
}

const likesSelector = {
  getLikes,
  isLiked,
};

export default likesSelector;
