import React from "react";

export default function YouTubeIcon({ fill, size, className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="11"
      viewBox="0 0 16 11"
      className={className}
    >
      <g fill="none" fillRule="evenodd">
        <g fill="#646E83" fillRule="nonzero">
          <g>
            <g>
              <g>
                <path
                  d="M15.32 1.06c-.434-.772-.905-.914-1.864-.968C12.498.027 10.089 0 8.002 0 5.911 0 3.501.027 2.544.091 1.587.146 1.115.287.677 1.06.23 1.831 0 3.159 0 5.497v.008c0 2.328.23 3.666.677 4.429.438.772.909.912 1.866.977.958.056 3.368.089 5.459.089 2.087 0 4.496-.033 5.455-.088.959-.065 1.43-.205 1.864-.977.451-.763.679-2.101.679-4.429v-.005-.003c0-2.339-.228-3.667-.68-4.438zM6 8.5v-6l5 3-5 3z"
                  transform="translate(-782 -2018) translate(0 1976) translate(588 30) translate(194 12)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
