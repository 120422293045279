import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import WebFont from "webfontloader";
import InvalidQRIllustration from "../../assets/images/invalid-qr.svg";
import Typography from "../../common/typography/index.component";
import { COLOR_BLACK } from "../../constants/colors.constants";
import renderHelmet from "../../helpers/helmetHelper";
import { translateOptions } from "../../i18n";

import "./index.component.scss";

function InvalidQrScanned({ t }) {
  const seoData = {
    title: t("qrMode.invalidQrHeading"),
    description: "",
    keywords: "",
  };

  useEffect(() => {
    WebFont.load({
      google: {
        families: [
          "Open Sans:400",
          "Open Sans:600",
          // added to load swapped fonts"
          "Open Sans:700&display=swap",
        ],
      },
    });

    // TODO: Need to fix
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="invalid-qr-wrapper">
      {renderHelmet(seoData)}
      <div className="invalid-qr-container">
        <div className="section-image">
          <img
            src={InvalidQRIllustration}
            alt="invalid-qr"
            className="qr-invalid-image"
          />
        </div>

        <Typography
          variant="h1"
          weight="bold"
          className="section-head"
          fontColor={COLOR_BLACK}
        >
          {"Invalid QR Code" || t("qrMode.invalidQrHeading")}
        </Typography>

        <Typography
          variant="h3"
          weight="regular"
          className="section-message"
          fontColor={COLOR_BLACK}
        >
          {"uh-oh! looks like the QR you have scanned is invalid, Please check with the restaurant." ||
            t("qrMode.invalidQrMessage")}
        </Typography>
      </div>
    </div>
  );
}

export default withTranslation(
  ["translations"],
  translateOptions
)(InvalidQrScanned);
