import React from "react";
export default function FacebookIcon({ fill, size, className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size.width}
      height={size.height}
      viewBox="0 0 8 14"
      className={className}
    >
      <g fill="none" fillRule="evenodd">
        <g fill={fill} fillRule="nonzero">
          <g>
            <g>
              <g>
                <path
                  d="M4.544 13.407V7.292h2.052l.308-2.384h-2.36V3.386c0-.69.191-1.16 1.182-1.16h1.261V.093C6.769.065 6.02 0 5.149 0c-1.82 0-3.066 1.11-3.066 3.15v1.758H.025v2.384h2.058v6.115h2.461z"
                  transform="translate(-601 -2017) translate(0 1976) translate(588 30) translate(13.654 11.26)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
