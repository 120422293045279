import React from "react";

export default function TwitterIcon({ fill, size, className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="14"
      viewBox="0 0 10 14"
      className={className}
    >
      <g fill="none" fillRule="evenodd">
        <g fill="#646E83" fillRule="nonzero">
          <g>
            <g>
              <g>
                <path
                  d="M8.274 9.926H5.008c-.454 0-.84-.159-1.157-.478-.319-.32-.476-.706-.476-1.162V7.124h4.653c.42 0 .781-.15 1.081-.45.3-.302.451-.663.451-1.083 0-.422-.15-.783-.451-1.084-.302-.3-.664-.45-1.086-.45H3.374V1.654c0-.454-.162-.843-.483-1.167C2.57.163 2.183 0 1.733 0 1.27 0 .876.16.556.478c-.322.318-.482.71-.482 1.178v6.631c0 1.364.482 2.53 1.446 3.496.965.97 2.13 1.452 3.49 1.452h3.265c.453 0 .842-.162 1.166-.487.324-.323.486-.713.486-1.167 0-.454-.162-.843-.486-1.168-.324-.324-.714-.487-1.167-.487z"
                  transform="translate(-739 -2017) translate(0 1976) translate(588 30) translate(151 11)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
