import React from "react";
export default function FiltersIcon({ fill, size, className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      className={className}
    >
      <defs>
        <path
          id="t2nro0wpna"
          d="M2 6.667h8V5.333H2v1.334zM0 2v1.333h12V2H0zm4.667 8h2.666V8.667H4.667V10z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <g>
                  <g transform="translate(-1322 -835) translate(32 753) translate(0 72) translate(1278) translate(12 10)">
                    <path d="M0 0H12V12H0z" />
                    <mask id="trk7sbe3mb" fill="#fff">
                      <use xlinkHref="#t2nro0wpna" />
                    </mask>
                    <use
                      fill="#000"
                      fillRule="nonzero"
                      xlinkHref="#t2nro0wpna"
                    />
                    <path
                      fill={fill}
                      d="M0 0H12V12H0z"
                      mask="url(#trk7sbe3mb)"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
