import React from "react";
export default function ChevronGroupIcon({ fill, size, className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      className={className}
    >
      <defs>
        <path
          id="s4xmj6nmra"
          d="M11.825 10L13 11.148 8 16l-5-4.852L4.175 10 8 13.712 11.825 10zM8 0l5 4.852L11.825 6 8 2.288 4.175 6 3 4.852 8 0z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <g transform="translate(-1145 -78) translate(32 62) translate(1057 12) translate(56 4)">
                  <path d="M0 0H16V16H0z" />
                  <mask id="b647pr5q1b" fill={fill ? fill : "#fff"}>
                    <use xlinkHref="#s4xmj6nmra" />
                  </mask>
                  <use fill="#000" fillRule="nonzero" xlinkHref="#s4xmj6nmra" />
                  <path fill={fill} d="M0 0H16V16H0z" mask="url(#b647pr5q1b)" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
