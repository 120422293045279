import React, { memo, useEffect, useContext } from "react";
import ArrowIcon from "../../../assets/icons/arrow.icon";
import fetchCatalogue from "common/store/actions/catalogue";
import AppButton from "../../../common/button/index.component";
import configSelector from "common/store/selectors/configSelector";
import Typography from "../../../common/typography/index.component";
import upsdkSelector from "common/store/selectors/upsdkSelector";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withTranslation, useTranslation } from "react-i18next";
import { translateOptions } from "../../../i18n";
import { ZIGZAG_SEPARATOR } from "../../../constants/image.constants";
import { XMasonry, XBlock } from "react-xmasonry";
import { BaseContext } from "../../../context/BaseContext";
import useRouteHook from "../../../hooks/useRoute.hook";

import "./index.component.scss";

function MenuSection(props) {
  const BaseConsumer = useContext(BaseContext);
  const { historyPush } = useRouteHook();

  // props
  const { config, categories, selectedStore, data } = props;

  // callbacks
  const { t, fetchCatalogue } = props;

  const { i18n } = useTranslation();

  // varialbes
  const location_id = selectedStore ? selectedStore.id : null;
  const primaryColor = configSelector.getPrimaryColor({ config });
  const secondaryColor = configSelector.getSecondaryColor({ config });
  const secondaryTextColor = configSelector.getSecondaryTextColor({ config });
  const { isMobileView, menuRoute } = BaseConsumer;

  useEffect(() => {
    fetchCatalogue();
    // eslint-disable-next-line
  }, [location_id]);

  const navigateToMenu = () => {
    const contentEle = document.getElementById("content-wrapper");
    sessionStorage.setItem("landingScrollPos", contentEle.scrollTop);
    historyPush(menuRoute);

    /**
     * can't use scrollToTop Hook here since it disrupts the overal
     * functionality of menu category scroll
     */
    window.scrollTo({
      top: 0,
      left: 0,
    });
  };

  const navigateToCategory = (category) => {
    const { slug } = category;
    const contentEle = document.getElementById("content-wrapper");
    sessionStorage.setItem("landingScrollPos", contentEle.scrollTop);
    historyPush(`${menuRoute}/${slug}`);
  };

  const getStyle = (i, length = 0) => {
    const defaultHeight = isMobileView ? 240 : 350;
    const defaultXBlock = length === 3 ? 4 : isMobileView ? 1 : 2;

    switch (i) {
      case 0:
        return {
          height: isMobileView ? 240 : 500,
          xBlock: isMobileView ? 1 : 3,
        };
      case 1:
        return {
          height: 240,
          xBlock: 1,
        };
      case 2:
        return {
          height: 240,
          xBlock: 1,
        };
      case 3:
        return {
          height: length === 3 ? 400 : defaultHeight,
          xBlock: defaultXBlock,
        };
      default:
        return {
          height: defaultHeight,
          xBlock: isMobileView ? 1 : 2,
        };
    }
  };

  const CategoryList = () => (
    <div>
      <XMasonry>
        {categories?.slice(0, 7).map((category, i) => (
          <XBlock
            width={getStyle(i, categories.length).xBlock}
            key={category.id}
          >
            <div
              id={category.id}
              key={category.id}
              className="category-tile"
              style={{
                backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.10), rgba(0, 0, 0, 0.52)),url(${category.image})`,
                ...getStyle(i, categories.length),
              }}
              onClick={() => navigateToCategory(category)}
            >
              <div className="text-wrapper">
                <Typography
                  variant="h1"
                  weight="semiBold"
                  className="category-name"
                  fontColor={"#ffffff"}
                >
                  {category.name}
                </Typography>
              </div>
            </div>
          </XBlock>
        ))}
      </XMasonry>
    </div>
  );

  const CategoryListPlaceholder = () => (
    <div className="category-list-placeholder">
      <div className="category-row box1">
        <div className="first-category"></div>
      </div>
      <div className="category-row box2">
        <div className="second-category"></div>
      </div>
      <div className="category-row box3">
        <div className="third-category"></div>
      </div>
    </div>
  );

  return (
    <div className="menu-section-wrapper">
      <div className="menu-section-container container">
        <Typography
          variant="h1"
          weight="semiBold"
          className="section-title"
          fontColor={secondaryColor}
        >
          {t("landingScreen.ourMenu")}
        </Typography>

        <img src={ZIGZAG_SEPARATOR} alt="separator" className="separator" />

        <Typography
          variant="h2"
          weight="regular"
          className="section-description"
          fontColor={secondaryTextColor}
        >
          {data.description}
        </Typography>

        <AppButton
          id="menu-button"
          variant="contained"
          className="menu-button"
          buttonColor={primaryColor}
          onClickCallback={navigateToMenu}
        >
          <Typography
            variant="h2"
            weight="semibold"
            className="menu-button-text"
            fontColor="#FFFFFF"
          >
            {t("landingScreen.checkTheMenu")}
          </Typography>
          <ArrowIcon
            className="button-icon"
            fill={"#ffffff"}
            rotate={i18n.dir() === "rtl" ? "180deg" : "0deg"}
          />
        </AppButton>

        {categories?.length ? <CategoryList /> : <CategoryListPlaceholder />}
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    config: state.config,
    selectedStore: upsdkSelector.getStore(state),
    categories: state.catalogue.data ? state.catalogue.data.categories : [],
  };
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchCatalogue: fetchCatalogue,
    },
    dispatch
  );

export default withRouter(
  withTranslation(
    ["translations"],
    translateOptions
  )(connect(mapStateToProps, mapDispatchToProps)(memo(MenuSection)))
);
