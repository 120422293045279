import {
  ApolloClient,
  HttpLink,
  ApolloLink,
  InMemoryCache,
  concat,
  split,
} from "@apollo/client";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { getMainDefinition } from "@apollo/client/utilities";

import LocalStorageHelper from "../../helpers/localStorage.helper";
import { store } from "../../store";
import configSelector from "../../store/selectors/configSelector";
import GET_QSR_STORE from "./queries/store.query";

const config = (window && window.ROOT_CONFIG) || {};
const uri = configSelector.getGraphqlBase({ config });
const wsUri = configSelector.getGraphqlWS({ config });
const token = configSelector.getAuthToken({ config });

const httpLink = new HttpLink({ uri });
const wsLink = new GraphQLWsLink(
  createClient({
    url: wsUri,
    connectionParams: () => ({
      authorization: store.getState().upsdk.auth_token,
      location_id: store.getState().upsdk.biz_location_id,
    }),
  })
);

const middleware = new ApolloLink((operation, forward) => {
  const definition = getMainDefinition(operation.query);
  const headers = {
    authorization:
      definition.name.value.match(/^User/) !== null
        ? store.getState().upsdk.auth_token
        : token,
    "x-use-lang": LocalStorageHelper.getLanguageFromLocalStorage(),
  };

  if (!headers.authorization) return;

  const selected_store = store.getState().upsdk.selected_store;
  const qrModeState = LocalStorageHelper.getQrModeState();
  const isGetStoreQuery = GET_QSR_STORE.GET_QSR_STORE.definitions.find(
    (def) => def.name.value === definition.name.value
  );

  if ((isGetStoreQuery || !selected_store) && qrModeState) {
    headers["state"] = qrModeState;
  } else {
    if (selected_store) {
      headers["location_id"] = selected_store.biz_location_id;
    }
  }

  operation.setContext(() => ({
    headers: headers,
  }));

  return forward(operation);
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

/**
 * creating Apollo Client Singleton
 */
export const client = (function () {
  let _singleton = null;

  return {
    getInstance: function (refresh = false) {
      if (!_singleton || refresh) {
        _singleton = createClientInstance();
      }
      return _singleton;
    },
  };
})();

const createClientInstance = () => {
  return new ApolloClient({
    cache: new InMemoryCache(),
    link: concat(middleware, splitLink),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "no-cache",
      },
    },
  });
};
