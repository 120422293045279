import pageSelector from "./pageSelector";

function showItemLikeButton({ config }) {
  return pageSelector.getMenuPage({ config }).customizations.likeButton;
}

function showFoodTypeIndicator({ config }) {
  return pageSelector.getMenuPage({ config }).customizations.showFoodType;
}

function showLikesCount({ config }) {
  return pageSelector.getMenuPage({ config }).customizations.showItemLikes;
}

function showSortWidget({ config }) {
  return pageSelector.getMenuPage({ config }).customizations.showItemSort;
}

function showFilterWidget({ config }) {
  return pageSelector.getMenuPage({ config }).customizations.showItemFilter;
}

function showSearchWidget({ config }) {
  return pageSelector.getMenuPage({ config }).customizations.showItemSearch;
}

function showRecommendedItemsWidget({ config }) {
  return pageSelector.getMenuPage({ config }).customizations.recommendedItems;
}

const itemSelector = {
  showLikesCount,
  showItemLikeButton,
  showFoodTypeIndicator,
  showSortWidget,
  showFilterWidget,
  showSearchWidget,
  showRecommendedItemsWidget,
};

export default itemSelector;
