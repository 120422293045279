import React from "react";

export default function DownArrowIcon({ fill, className }) {
  return (
    <svg
      width="10"
      height="6"
      viewBox="0 0 10 6"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      fill="none"
    >
      <path
        d="M1 1L5 5L9 1"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
