import configSelector from "../store/selectors/configSelector";
import LocalStorageHelper from "./localStorage.helper";
import dayjs from "dayjs";
import upsdkService from "../services/upsdkService";

const loadLocales = {
  en: import("dayjs/locale/en"),
  es: import("dayjs/locale/es"),
  ar: import("dayjs/locale/ar"),
};

/**
 * This helper is responsible for all the langauge related operations and
 * any future methods needed in relation with language should be added here
 */
export default class LanguageHelper {
  /**
   * precendence = languageFromLocalStorage > defaultLanguage > 'en'
   *
   * @param {object} config is needed to get the defaultLanguage set by business
   * @return {string} returns the active language eg: {'es'}
   */
  static getLanguage(config) {
    const defaultLanguage = configSelector.getDefaultLanguage({ config });
    const languageFromLocalStorage =
      LocalStorageHelper.getLanguageFromLocalStorage();
    return languageFromLocalStorage || defaultLanguage || "en";
  }

  /**
   * sets the language in i18n, moment and upsdkService
   *
   * @param {Callback} i18n, used to activate the languge.
   * Due to cyclic dependency i18n is not imported here, rather it is passed as a parameter to avoid errors.
   *
   * @param {String} language to be set as active eg: {'es'}
   */
  static onLanguageChange(i18n, language) {
    //dynamically loads locales
    loadLocales[language];
    i18n.changeLanguage(language);
    dayjs.locale(language);
    upsdkService.setLanguage(language);
  }
}
