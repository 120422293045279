import {
  SET_SUB_LOCALITY,
  CLEAR_SUB_LOCALITY,
  SET_LOCATION_DIALOG,
  UNSET_LOCATION_DIALOG,
  SET_SELECTED_ADDRESS,
  CLEAR_SELECTED_ADDRESS,
  SET_DASH_DELIVERY,
  CLEAR_DASH_DELIVERY,
  SET_SPECIAL_INSTRUCTION,
  CLEAR_SPECIAL_INSTRUCTION,
  SHOW_UPDATE_CART_ON_LOGIN,
  HIDE_UPDATE_CART_ON_LOGIN,
  SET_SHOW_DELIVER_NOW,
} from "../actions/ui";

const initialState = {
  subLocality: null,
  showLocationDialog: false,
  selectedAddress: null,
  isGetDeliveryEstimateCalledOnce: false,
  dashDelivery: { estimated_price: 0, bid_id: null, exp_at: null },
  specialInstruction: "",
  showUpdateCartOnLogin: false,
  showDeliverNow: true,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_SUB_LOCALITY: {
      const newState = {
        ...state,
        subLocality: action.data,
      };
      return newState;
    }
    case CLEAR_SUB_LOCALITY: {
      const newState = {
        ...state,
        subLocality: null,
      };
      return newState;
    }

    case SET_LOCATION_DIALOG: {
      const newState = {
        ...state,
        showLocationDialog: true,
      };
      return newState;
    }
    case UNSET_LOCATION_DIALOG: {
      const newState = {
        ...state,
        showLocationDialog: false,
      };
      return newState;
    }

    case SET_SELECTED_ADDRESS: {
      const newState = {
        ...state,
        selectedAddress: action.data,
      };
      return newState;
    }
    case CLEAR_SELECTED_ADDRESS: {
      const newState = {
        ...state,
        selectedAddress: null,
      };
      return newState;
    }

    case SET_DASH_DELIVERY: {
      return {
        ...state,
        isGetDeliveryEstimateCalledOnce: true,
        dashDelivery: action.data,
      };
    }

    case CLEAR_DASH_DELIVERY: {
      return {
        ...state,
        isGetDeliveryEstimateCalledOnce: false,
        dashDelivery: { estimated_price: 0, bid_id: null, exp_at: null },
      };
    }

    case SET_SPECIAL_INSTRUCTION: {
      return {
        ...state,
        specialInstruction: action.data,
      };
    }

    case CLEAR_SPECIAL_INSTRUCTION: {
      return {
        ...state,
        specialInstruction: "",
      };
    }

    case SHOW_UPDATE_CART_ON_LOGIN: {
      return {
        ...state,
        showUpdateCartOnLogin: true,
      };
    }

    case HIDE_UPDATE_CART_ON_LOGIN: {
      return {
        ...state,
        showUpdateCartOnLogin: false,
      };
    }

    case SET_SHOW_DELIVER_NOW: {
      return {
        ...state,
        showDeliverNow: action.data,
      };
    }

    default:
      return state;
  }
};
