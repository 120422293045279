import React from "react";

export default function SortByIcon({ fill, size, className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      className={className}
    >
      <defs>
        <path
          id="9l58afrp9a"
          d="M2.945 0c.06 0 .111.02.15.059.04.04.06.09.06.151v6.27h1.26c.06 0 .11.02.15.06.04.04.06.09.06.15 0 .053-.023.106-.066.158L2.466 8.941c-.044.04-.094.059-.151.059-.053 0-.103-.02-.151-.059l-2.1-2.1c-.065-.07-.08-.146-.046-.23.035-.087.1-.13.197-.13h1.26V.21c0-.061.02-.112.059-.151.04-.04.09-.059.15-.059zm5.879 6.719c.06 0 .111.02.15.059.04.04.06.09.06.15v1.26c0 .062-.02.112-.06.151-.039.04-.09.06-.15.06h-2.94c-.061 0-.111-.02-.15-.06-.04-.039-.06-.09-.06-.15v-1.26c0-.061.02-.112.06-.151.039-.04.089-.06.15-.06zm1.26-3.36c.06 0 .11.02.15.06.04.039.06.09.06.15v1.26c0 .061-.02.112-.06.151-.04.04-.09.06-.15.06h-4.2c-.061 0-.111-.02-.15-.06-.04-.04-.06-.09-.06-.15V3.57c0-.062.02-.112.06-.151.039-.04.089-.06.15-.06zM11.343 0c.06 0 .11.02.15.059.04.04.06.09.059.151v1.26c0 .061-.02.111-.059.15-.04.04-.09.06-.15.06h-5.46c-.061 0-.111-.02-.15-.06-.04-.039-.06-.089-.06-.15V.21c0-.061.02-.112.06-.151.039-.04.089-.059.15-.059z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <g>
                  <path
                    d="M0 0H12V12H0z"
                    transform="translate(-1211 -668) translate(32 586) translate(0 72) translate(1167) translate(12 10)"
                  />
                  <g transform="translate(-1211 -668) translate(32 586) translate(0 72) translate(1167) translate(12 10) translate(0 2)">
                    <mask id="g82yc3sgvb" fill="#fff">
                      <use xlinkHref="#9l58afrp9a" />
                    </mask>
                    <use
                      fill="#297BFF"
                      fillRule="nonzero"
                      xlinkHref="#9l58afrp9a"
                    />
                    <path
                      fill={fill}
                      d="M0 -2H12V10H0z"
                      mask="url(#g82yc3sgvb)"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
