import React, { Suspense } from "react";
import { connect } from "react-redux";

import configSelector from "common/store/selectors/configSelector";
import fontHelper from "../../helpers/fontHelper";

import "./index.component.scss";

function Typography({
  style,
  variant,
  children,
  fontColor,
  textWrap,
  config,
  weight,
  onClickCallback,
  className,
  isRequired = false,
  onMouseEnter = () => {},
  onMouseLeave = () => {},
}) {
  //variables
  const selectedFontFamily = configSelector.getFontFamily({ config });
  const fontStyle = fontHelper(selectedFontFamily, variant, weight);
  const typoStyles = {
    ...fontStyle,
    ...style,
    color: fontColor,
    whiteSpace: textWrap,
  };
  const typoClasses = `${variant} ${className || ""}`;

  const suspensefulChildren = (
    <Suspense fallback={" "}>
      {children} {isRequired && <span className="input-required">*</span>}{" "}
    </Suspense>
  );

  if (variant === "h1") {
    return (
      <h1
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        style={typoStyles}
        onClick={onClickCallback}
        className={typoClasses}
      >
        {suspensefulChildren}
      </h1>
    );
  } else if (variant === "h2") {
    return (
      <h2
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        style={typoStyles}
        onClick={onClickCallback}
        className={typoClasses}
      >
        {suspensefulChildren}
      </h2>
    );
  } else if (variant === "h3") {
    return (
      <h3
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        style={typoStyles}
        onClick={onClickCallback}
        className={typoClasses}
      >
        {suspensefulChildren}
      </h3>
    );
  } else if (variant === "h4") {
    return (
      <h4
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        style={typoStyles}
        onClick={onClickCallback}
        className={typoClasses}
      >
        {suspensefulChildren}
      </h4>
    );
  } else if (variant === "h5") {
    return (
      <h5
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        style={typoStyles}
        onClick={onClickCallback}
        className={typoClasses}
      >
        {suspensefulChildren}
      </h5>
    );
  } else if (variant === "para") {
    return (
      <p
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        style={typoStyles}
        onClick={onClickCallback}
        className={typoClasses}
      >
        {suspensefulChildren}
      </p>
    );
  }

  return (
    <h6
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={typoStyles}
      onClick={onClickCallback}
      className={typoClasses}
    >
      {suspensefulChildren}
    </h6>
  );
}

function mapStateToProps(state) {
  return {
    config: state.config,
  };
}

export default connect(mapStateToProps, null)(Typography);
