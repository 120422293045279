import React from "react";
import classNames from "classnames";

import "./index.component.scss";

function PopOver({ children, open, className, style }) {
  return (
    <div
      className={classNames(className, {
        "show-popover": open,
        "hide-popover": !open,
      })}
      style={style}
    >
      {children}
    </div>
  );
}

export default PopOver;
