import { useState, useEffect, useRef } from "react";

import debounce from "common/helpers/debounce";

/**
 * Get the bounding client rect for an element using a react ref. As the bounding rect
 * changes the value will change in near-realtime
 *
 * @param {React.Ref} target
 * @returns {{left: number, right: number, top: number, bottom: number}} bounding client rect object
 */
const useBoundingClientRect = (target) => {
  const sizeWatcher = useRef();
  const [size, setSize] = useState(null);

  /**
   * Using a window resize event instead of a resize observer as bounding client rect
   * can change even if the element itself does not resize, but the window resizes.
   */
  useEffect(() => {
    const listener = window.addEventListener(
      "resize",
      debounce(() => {
        if (target.current) {
          setSize(target.current.getBoundingClientRect());
        }
      }, 500)
    );

    return () => window.removeEventListener("resize", listener);
  }, [target]);

  /**
   * If size is empty, register a watcher which will poll for the size.
   * Sometimes in the initial mount the size is null
   */
  useEffect(() => {
    if (size) {
      if (sizeWatcher.current) {
        clearInterval(sizeWatcher.current);
      }

      return;
    }

    sizeWatcher.current = setInterval(() => {
      if (target.current) {
        setSize(target.current.getBoundingClientRect());
      }
    }, 200);
  }, [size, target]);

  return size ? size : { left: 0, right: 0, top: 0, bottom: 0 };
};

export default useBoundingClientRect;
