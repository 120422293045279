const MiscHelper = {
  /**
   * Is the website loaded in screenshot mode?
   */
  isScreenShotMode() {
    return /screenshot_mode/.test(window.location.search);
  },

  /**
   * Checks if the operating system windows
   */
  isWindowsOS() {
    if (window?.navigator?.platform === "Win32") {
      return true;
    }
    return false;
  },
};

export default MiscHelper;
