export const constructSlug = (itemName) => {
  if (itemName && itemName.length) {
    const lowerCaseStr = itemName.toLowerCase();
    const validatedSlug = validateUrl(lowerCaseStr);
    const slug = validatedSlug.replace(/\s/g, "-");
    return slug;
  }
  return itemName;
};

const validateUrl = (url) => {
  if (url && url.length) {
    return url.replace(/\//g, "");
  }
  return url;
};
