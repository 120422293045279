class LocationHelper {
  static parseLocationId(state) {
    try {
      const locationId = state.split("/")[1];
      return locationId;
    } catch (error) {
      return null;
    }
  }
}

export default LocationHelper;
