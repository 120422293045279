import React from "react";
export default function InstagramIcon({
  fill,
  size,
  className,
  onClickCallback,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      className={className}
    >
      <defs>
        <path
          id="lkqiolgd7a"
          d="M2.84 31.89c-.631-.15-1.256-.507-1.75-.994-.5-.506-.794-1.006-.969-1.669-.112-.444-.119-.862-.119-13.206C-.004 2.152-.023 2.915.34 2.133.696 1.353 1.384.671 2.159.327c.806-.356.018-.337 13.993-.319l12.875.013.4.137c1.17.413 1.988 1.232 2.4 2.4l.138.4.012 12.875c.02 14.3.05 13.232-.406 14.157-.306.63-.937 1.262-1.581 1.58-.913.45.125.42-14.025.413-10.819-.006-12.806-.018-13.125-.093zm24.912-3.807c.513-.362.525-.4.544-2.78.019-2.32.006-2.438-.375-2.807-.344-.338-.506-.356-2.769-.35l-2.062.006-.281.15c-.157.088-.357.275-.463.438l-.187.275-.007 2.068c-.006 1.132.02 2.163.05 2.282.088.35.3.618.638.787l.312.156 2.17-.018 2.162-.02.268-.187zM17.24 22.015c2.394-.494 4.281-2.394 4.787-4.813.107-.506.144-1.525.07-2.031-.27-1.925-1.507-3.694-3.207-4.6-.5-.269-1.281-.556-1.65-.613-.119-.012-.369-.05-.556-.08-.775-.113-1.72-.013-2.588.268-1.225.4-2.225 1.112-2.987 2.119-1.725 2.287-1.682 5.35.118 7.593.982 1.232 2.57 2.094 4.125 2.244.638.063 1.3.031 1.888-.087zM6.359 17.958c-.444-2.062-.138-4.425.825-6.343.512-1.02 1.018-1.713 1.868-2.57 2.263-2.262 5.307-3.255 8.507-2.774 3.762.556 7.018 3.519 7.962 7.25.3 1.2.375 2.181.256 3.5-.037.444-.193 1.25-.256 1.35-.019.031-.012.056.019.062.237.025 2.669.02 2.712-.012.038-.019.05-2.794.044-6.975l-.019-6.944-.187-.269c-.106-.15-.306-.325-.469-.412l-.281-.144-11.413.019-11.406.012-.256.17c-.138.093-.319.274-.4.405l-.15.238-.019 6.906c-.006 3.8-.006 6.931.013 6.969.012.037.518.062 1.393.062h1.37l-.113-.5z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <g>
                  <g transform="translate(-1191.000000, -599.000000) translate(928.000000, 482.000000) translate(167.000000, 39.000000) translate(80.000000, 62.000000) translate(16.000000, 16.000000) translate(-0.000000, 0.000000)">
                    <path d="M0 0H32V32H0z" />
                    <mask id="gcrky1epgb" fill="#fff">
                      <use xlinkHref="#lkqiolgd7a" />
                    </mask>
                    <use
                      fill={fill}
                      fillRule="nonzero"
                      transform="translate(15.993107, 15.993127) scale(1, -1) translate(-15.993107, -15.993127)"
                      xlinkHref="#lkqiolgd7a"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
