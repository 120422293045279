import activeRadioIcon from "../assets/icons/active-radio-icon.png";
import checkedCheckboxIcon from "../assets/icons/checked-icon.png";
import inActiveRadioButtonIcon from "../assets/icons/inactive-radio-button.svg";
import inActiveRadioIcon from "../assets/icons/inactive-radio-icon.png";
import noResultsFoundIcon from "../assets/icons/no-results-found.png";
import referAndEarnIllustration from "../assets/icons/refer-and-earn.jpg";
import startNavigationIcon from "../assets/icons/start-navigation.png";
import uncheckedCheckboxIcon from "../assets/icons/unchecked-icon.png";
import landingScreenHeroBackground from "../assets/images/landing-page-hero-bg.jpg";
import zigZagSeparator from "../assets/images/zig-zag-line.png";
import imagePlaceholder from "../assets/images/placeholder.jpg";
import androidStoreIcon from "../assets/images/android-store.png";
import appleStoreIcon from "../assets/images/apple-store.png";

export const NO_RESULTS_FOUND_ICON = noResultsFoundIcon;
export const REFER_AND_EARN_ICON = referAndEarnIllustration;
export const INACTIVE_RADIO_BUTTON = inActiveRadioButtonIcon;
export const ANDROID_STORE_ICON = androidStoreIcon;
export const APPLE_STORE_ICON = appleStoreIcon;
export const START_NAVIGATION_ICON = startNavigationIcon;
export const ACTIVE_RADIO_ICON = activeRadioIcon;
export const INACTIVE_RADIO_ICON = inActiveRadioIcon;
export const CHECKED_CHECKBOX_ICON = checkedCheckboxIcon;
export const UNCHECKED_CHECKBOX_ICON = uncheckedCheckboxIcon;
export const LANDING_SCREEN_HERO_BACKGROUND = landingScreenHeroBackground;
export const ZIGZAG_SEPARATOR = zigZagSeparator;
export const IMAGE_PLACEHOLDER = imagePlaceholder;
