import React from "react";
export default function GooglePlusIcon({ fill, size, className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      className={className}
    >
      <g fill="none" fillRule="evenodd">
        <g fill="#646E83" fillRule="nonzero">
          <g>
            <g>
              <g>
                <path
                  d="M8.927 0H5.563c-1.5 0-2.537.326-3.471 1.092C1.357 1.72.919 2.635.919 3.537c0 1.39 1.068 2.868 3.047 2.868.19 0 .4-.02.587-.037l-.028.066c-.08.19-.155.368-.155.646 0 .542.264.877.519 1.2l.032.04-.057.005c-.817.055-2.339.159-3.45.836C.102 9.936 0 11.062 0 11.39 0 12.689 1.22 14 3.947 14c3.17 0 4.83-1.736 4.83-3.45 0-1.269-.75-1.895-1.545-2.56l-.671-.517c-.207-.17-.465-.383-.465-.776 0-.386.258-.641.486-.866l.023-.024c.725-.566 1.546-1.208 1.546-2.597 0-1.397-.88-2.118-1.302-2.465h1.116c.014 0 .028-.004.04-.011l.961-.6c.027-.018.04-.05.031-.082C8.99.022 8.96 0 8.927 0zm-4.08 13.235c-1.932 0-3.23-.896-3.23-2.229 0-.87.53-1.503 1.576-1.88.838-.279 1.92-.293 1.93-.293.183 0 .275 0 .421.018 1.353.955 2.003 1.457 2.003 2.412 0 1.216-1.035 1.972-2.7 1.972zM4.83 5.893c-1.622 0-2.295-2.116-2.295-3.249 0-.576.132-1.018.404-1.352.294-.366.8-.602 1.285-.602 1.49 0 2.313 1.986 2.313 3.36 0 .216 0 .875-.459 1.332-.308.306-.81.51-1.248.51zM13.92 6.996h-1.93V5.079c0-.044-.036-.079-.08-.079h-.83c-.044 0-.08.035-.08.079v1.917H9.08c-.044 0-.08.035-.08.079v.838c0 .043.036.08.08.08H11V9.92c0 .043.036.079.08.079h.83c.044 0 .08-.036.08-.079v-1.93h1.93c.044 0 .08-.035.08-.078v-.838c0-.044-.035-.08-.08-.08z"
                  transform="translate(-645 -2017) translate(0 1976) translate(588 30) translate(57 11)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
