import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import seoHelper from "common/helpers/seoHelper";
import configSelector from "common/store/selectors/configSelector";

import renderHelmet from "../../helpers/helmetHelper";
import { translateOptions } from "../../i18n";
import { renderGenericComponents } from "../../helpers/renderComponent";

import "./index.component.scss";

function CustomPage(props) {
  // props
  const { config } = props;

  // variables

  const currentCustomRoute = props.location.pathname;
  const currentCustomPage = configSelector
    .getCustomPages({ config })
    .find((page) => {
      return currentCustomRoute.includes(page?.path);
    });
  const selectedFontFamily = configSelector.getFontFamily({ config });
  const seoData = seoHelper(currentCustomPage?.seo);

  return (
    <div className="custom-page-wrapper">
      <div
        style={{ fontFamily: selectedFontFamily }}
        className="container custom-content-container"
      >
        {renderHelmet(seoData)}
        <div dangerouslySetInnerHTML={{ __html: currentCustomPage.content }} />
        {renderGenericComponents(currentCustomPage.components || [], props)}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    config: state.config,
  };
};

export default withTranslation(
  ["translations"],
  translateOptions
)(connect(mapStateToProps, null)(CustomPage));
