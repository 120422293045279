import React from "react";

export default function ShoppingCartIcon({ fill, size, className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      className={className}
    >
      <defs>
        <path
          id="g5j8gdiexa"
          d="M16 16c-1.11 0-2 .89-2 2 0 1.105.895 2 2 2s2-.895 2-2c0-1.11-.9-2-2-2zM0 0v2h2l3.6 7.59-1.36 2.45c-.15.28-.24.61-.24.96 0 1.105.895 2 2 2h12v-2H6.42c-.138 0-.25-.112-.25-.25 0-.05.01-.09.03-.12L7.1 11h7.45c.75 0 1.41-.42 1.75-1.03l3.58-6.47c.07-.16.12-.33.12-.5 0-.552-.448-1-1-1H4.21l-.94-2H0zm6 16c-1.11 0-2 .89-2 2 0 1.105.895 2 2 2s2-.895 2-2c0-1.11-.9-2-2-2z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <g>
                  <g transform="translate(-1374 -76) translate(32 62) translate(1328) translate(14 14)">
                    <path d="M0 0H20V20H0z" />
                    <mask id="4vwijsp8hb" fill="#fff">
                      <use xlinkHref="#g5j8gdiexa" />
                    </mask>
                    <use
                      fill="#000"
                      fillRule="nonzero"
                      xlinkHref="#g5j8gdiexa"
                    />
                    <path
                      fill={fill}
                      d="M0 0H20V20H0z"
                      mask="url(#4vwijsp8hb)"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
