import React, { Fragment, useContext } from "react";
import { useTranslation } from "react-i18next";

import { getFooterLinks } from "common/helpers/getFooterLinks";
import configSelector from "common/store/selectors/configSelector";
import pageSelector from "common/store/selectors/pageSelector";

import FacebookIcon from "../../assets/icons/facebook.icon";
import GooglePlusIcon from "../../assets/icons/google-plus.icon";
import InstagramIcon from "../../assets/icons/instagram.icon";
import LinkedinIcon from "../../assets/icons/linkedin.icon";
import TwitterIcon from "../../assets/icons/twitter.icon";
import YouTubeIcon from "../../assets/icons/youtube.icon";
import UrbanPiperLogo from "../../assets/images/urban_piper_logo.svg";
import version from "../../version.json";
import Typography from "../typography/index.component";
import FooterHelper from "../../helpers/footer.helper";
import { BaseContext } from "../../context/BaseContext";
import ScreenHelper from "../../helpers/screen.helper";
import useRouteHook from "../../hooks/useRoute.hook";
import {
  COLOR_ASH_GREY,
  COLOR_BLACK,
} from "../../constants/colors.constants.js";

import "./index.component.scss";

export default function Footer({
  config,
  primaryColor,
  secondaryColor,
  primaryTextColor,
  secondaryTextColor,
}) {
  // variables
  const { isQrModeEnabled } = useContext(BaseContext);
  const storeLocatorPageConfig = pageSelector.getStoreLocatorPage({ config });
  const footerLinks = getFooterLinks(configSelector.getCustomPages({ config }));
  const socialMediaLinks = getSocialMediaCollection(
    configSelector.getSocialMediaLinks({ config })
  );
  const externalPageLinks = configSelector.getExternalPageLinks({ config });
  const copyrightText = configSelector.getCopyrightText({ config });
  const poweredByTextFlag = configSelector.getUrbanPiperBrandingFlag({
    config,
  });
  const contactUs = configSelector.getContactUs({ config });

  const { pathname, CustomLink } = useRouteHook();
  const { t } = useTranslation();

  const isCheckoutScreen = ScreenHelper.isCheckoutScreen(pathname);
  const isCartScreen = ScreenHelper.isCartScreen(pathname);

  // ToDo: move this fuction to trasfer
  function getSocialMediaCollection(mediaLinks) {
    let socialMediaLinksCollection = [];
    for (const media in mediaLinks) {
      if (media && mediaLinks[media]) {
        socialMediaLinksCollection.push({
          media: media,
          link: mediaLinks[media],
          icon: getMediaIcon(media),
        });
      }
    }
    return socialMediaLinksCollection;
  }

  // ToDo: move this function to trasfer
  function getMediaIcon(media) {
    switch (media) {
      case "facebook":
        return (
          <FacebookIcon
            className="media-img"
            fill="#646E83"
            size={{ width: 8, height: 14 }}
          />
        );
      case "linkedin":
        return <LinkedinIcon className="media-img" />;
      case "twitter":
        return <TwitterIcon className="media-img" />;
      case "youtube":
        return <YouTubeIcon className="media-img" />;
      case "googleplus":
        return <GooglePlusIcon className="media-img" />;
      case "instagram":
        return <InstagramIcon className="media-img" fill="#646E83" />;
      default:
    }
  }

  const onMouseEnter = (e, type) => {
    if (type === "media") {
      e.target.style.backgroundColor = primaryColor;
    } else {
      e.target.style.color = primaryColor;
    }
  };

  const onMouseLeave = (e, type) => {
    if (type === "media") {
      e.target.style.backgroundColor = "initial";
    } else {
      e.target.style.color = "initial";
    }
  };

  if (
    isCheckoutScreen ||
    isCartScreen ||
    ScreenHelper.isLandingScreen(pathname)
  ) {
    return <Fragment />;
  }

  return (
    <div
      className="footer-wrapper"
      style={isQrModeEnabled ? { marginBottom: 0 } : {}}
    >
      {!isQrModeEnabled && (
        <div className="footer-container container">
          {FooterHelper.enableMore(
            storeLocatorPageConfig,
            footerLinks,
            externalPageLinks
          ) && (
            <div className="footer-more">
              <Typography
                className="footer-sub-heading"
                variant="h4"
                weight="regular"
                fontColor={secondaryColor}
              >
                {t("footer.more")}
              </Typography>
              <ul className="footer-link-list">
                {storeLocatorPageConfig.enabled && (
                  <li className="footer-link">
                    <CustomLink to="/store-locator">
                      <Typography
                        variant="h3"
                        weight="regular"
                        fontColor={primaryTextColor}
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}
                      >
                        {t("footer.storeLocator")}
                      </Typography>
                    </CustomLink>
                  </li>
                )}
                {footerLinks.map((link, index) => (
                  <li key={index} className="footer-link">
                    <CustomLink to={link.path}>
                      <Typography
                        variant="h3"
                        weight="regular"
                        fontColor={primaryTextColor}
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}
                      >
                        {link.name}
                      </Typography>
                    </CustomLink>
                  </li>
                ))}
                {externalPageLinks.map((link, index) => (
                  <li key={index} className="footer-link">
                    <a
                      href={link.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Typography
                        variant="h3"
                        weight="regular"
                        fontColor={primaryTextColor}
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}
                      >
                        {link.name}
                      </Typography>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          )}
          {FooterHelper.enableContactUs(contactUs) && (
            <div className="footer-contact-us">
              <Typography
                className="footer-sub-heading"
                variant="h4"
                weight="regular"
                fontColor={secondaryColor}
              >
                {t("footer.contactUs")}
              </Typography>
              <ul className="footer-link-list">
                <li className="footer-link">
                  {contactUs.phone && (
                    // need the phone number in ltr format all the time
                    <a dir="ltr" href={`tel:${contactUs.phone}`}>
                      <Typography
                        variant="h3"
                        weight="regular"
                        fontColor={primaryTextColor}
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}
                      >
                        {contactUs.phone}
                      </Typography>
                    </a>
                  )}
                </li>

                <li className="footer-link">
                  {contactUs.email && (
                    <a href={`mailto:${contactUs.email}`}>
                      <Typography
                        variant="h3"
                        weight="regular"
                        fontColor={primaryTextColor}
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}
                      >
                        {contactUs.email}
                      </Typography>
                    </a>
                  )}
                </li>

                <li className="footer-link">
                  {contactUs.address && (
                    <Typography
                      variant="h3"
                      weight="regular"
                      fontColor={primaryTextColor}
                      onMouseEnter={onMouseEnter}
                      onMouseLeave={onMouseLeave}
                    >
                      {contactUs.address}
                    </Typography>
                  )}
                </li>
              </ul>
            </div>
          )}

          {FooterHelper.enableFollowUs(socialMediaLinks) && (
            <div className="footer-follow-us">
              <Typography
                className="footer-sub-heading"
                variant="h4"
                weight="regular"
                fontColor={secondaryColor}
              >
                {t("footer.followUs")}
              </Typography>
              <ul className="social-media-list">
                {socialMediaLinks.length
                  ? socialMediaLinks.map((media, index) => (
                      <a
                        onMouseEnter={(e) => onMouseEnter(e, "media")}
                        onMouseLeave={(e) => onMouseLeave(e, "media")}
                        href={media.link}
                        target="_blank"
                        className="media-link"
                        rel="noreferrer"
                        key={index}
                      >
                        {media.icon}
                      </a>
                    ))
                  : null}
              </ul>
            </div>
          )}
        </div>
      )}

      {isQrModeEnabled && (
        <div className="footer-container container">
          <div className="footer-more">
            <ul className="footer-link-list">
              {footerLinks.map((link, index) => (
                <li key={index} className="footer-link">
                  <CustomLink to={link.path}>
                    <Typography
                      variant="h3"
                      weight="regular"
                      fontColor={primaryTextColor}
                      onMouseEnter={onMouseEnter}
                      onMouseLeave={onMouseLeave}
                    >
                      {link.name}
                    </Typography>
                  </CustomLink>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}

      <div className="bottom-text-wrapper container">
        <div className="powered-by">
          {poweredByTextFlag && (
            <a
              href="https://www.urbanpiper.com"
              target="_blank"
              rel="noreferrer"
              className="powered-by"
            >
              <Typography
                variant="para"
                weight="semiBold"
                className="powered-by-text"
                fontColor={COLOR_BLACK}
              >
                {t("footer.poweredByText")}
              </Typography>
              <img
                src={UrbanPiperLogo}
                alt="urban-piper-logo"
                className="powered-by-logo"
                width={135}
                height={29}
              />
            </a>
          )}
        </div>

        <Typography
          variant="h4"
          weight="regular"
          className="copyright"
          fontColor={COLOR_BLACK}
        >
          {copyrightText}
        </Typography>

        <div className="version">
          <Typography variant="h4" weight="regular" fontColor={COLOR_ASH_GREY}>
            {`V ${version.version}`}
          </Typography>
        </div>
      </div>
    </div>
  );
}
