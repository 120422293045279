import MiscHelper from "common/helpers/misc.helper";
import React, {
  useRef,
  useCallback,
  useEffect,
  useState,
  forwardRef,
} from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dialog, Slide, TextField } from "@material-ui/core";

import { bindActionCreators } from "redux";
import LanguageHelper from "common/helpers/language.helper";
import googlePlacesService from "common/services/googlePlaces";
import {
  setLocationDialog,
  setSubLocality,
  unSetLocationDialog,
} from "common/store/actions/ui";
import upsdkSelector from "common/store/selectors/upsdkSelector";
import ChevronGroupIcon from "../../assets/icons/chevron-group.icon";
import DownArrowIcon from "../../assets/icons/downArrow.icon";
import SearchLocationIcon from "../../assets/icons/search-location.icon";
import useBoundingClientRect from "../../hooks/useBoundingClientRect.hook";
import { translateOptions } from "../../i18n";
import Tooltip from "../tooltip/index.component";
import SearchCard from "./search-card/index.component";
import Typography from "../typography/index.component";
import { COLOR_WHITE } from "../../constants/colors.constants";

import "./index.component.scss";

function LocationSearch({
  t,
  i18n,
  unSetLocationDialog,
  upsdk,
  isMobileView,
  userData,
  subLocality,
  selectedStore,
  selectedType,
  secondaryTextColor,
  showLocationDialog,
  secondaryColor,
  headerColorConfig,
  isDarkHeader,
  config,
  fulfilmentCallback,
}) {
  const locationSearchButton = useRef(null);
  const locationSearchDialog = useRef(null);
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [places, setPlaces] = useState([]);
  const [locationText, setLocationText] = useState(null);
  const locationSearchButtonLeft =
    useBoundingClientRect(locationSearchButton)?.left;
  const screenWidth = window.innerWidth;
  const dialogState = "search";
  const [locationSearchAutoFocus, setLocationSearchAutoFocus] = useState(true);

  // variables
  const addresses = userData ? userData.addresses : [];
  const bizInfo = upsdkSelector.getBizInfo({ upsdk });

  const activeLanguage = LanguageHelper.getLanguage(config);
  const direction = i18n.dir();

  useEffect(() => {
    let value = "";
    if (selectedType === "delivery") {
      value = t("common.searchAddress");
      if (subLocality) {
        value = subLocality.name || subLocality.tag;
      }
    }
    if (selectedType === "pickup") {
      value = t("common.selectStore");
      if (selectedStore) {
        value = selectedStore.name;
      }
    }
    setLocationText(value);
  }, [subLocality, selectedType, selectedStore, t, activeLanguage]);

  useEffect(() => {
    if (open) {
      setLocationSearchAutoFocus(true);
    }
  }, [open]);

  useEffect(() => {
    const locationSearchDialogEle = locationSearchDialog.current;
    const locationSearchButtonEle = locationSearchButton.current;
    if (!locationSearchButtonLeft || !locationSearchDialogEle) {
      return;
    }

    if (!isMobileView) {
      if (direction === "rtl") {
        const position =
          screenWidth - locationSearchButtonEle.getBoundingClientRect().right;
        locationSearchDialogEle.style.right = `${position}px`;
      } else {
        const position = locationSearchButtonEle.getBoundingClientRect().left;
        locationSearchDialogEle.style.left = `${position}px`;
      }
    }
  }, [isMobileView, locationSearchButtonLeft, direction, open, screenWidth]);

  useEffect(() => {
    if (MiscHelper.isScreenShotMode()) {
      return;
    }

    if (!selectedStore && showLocationDialog) {
      setOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedType, showLocationDialog, isMobileView, selectedStore]);

  const handleClose = () => {
    setTimeout(() => {
      setOpen(false);
    }, 0);
    unSetLocationDialog();
  };

  //doesn't work as desired with debounce
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const googleAutoComplete = useCallback(
    (_searchVal) => {
      if (!_searchVal.trim()) return;
      googlePlacesService
        .search(_searchVal, bizInfo.isd_code)
        .then((response) => {
          setPlaces(response);
        });
    },
    [bizInfo]
  );

  const getLocationIconColor = () => {
    if (isDarkHeader) {
      return headerColorConfig.iconColor;
    } else {
      return secondaryColor;
    }
  };

  const handleOnChange = (event) => {
    setSearchQuery(event.target.value);
    googleAutoComplete(event.target.value);
  };

  const handleOnFocus = () => {
    /*
          On Mobile browsers, due to keyboard pop up, the UI auto-scrolls and the user looses the position he/she was before the location search button was pressed.
          * To overcome this behaviour, we are storing the scroll position on textfield focus(keyboard pop up) in session storage and when the textfield goes out of focus we scroll down to stored position.
        */
    sessionStorage.setItem("mobileScrollPosition", window.pageYOffset);
  };

  const handleOnBlur = () => {
    //for better transition we need to make sure textfield is not focused when the dialog is about to close
    setLocationSearchAutoFocus(false);
    if (isMobileView) {
      const mobileScrollPosition = sessionStorage.getItem(
        "mobileScrollPosition"
      );
      window.scrollTo(0, mobileScrollPosition);
    }
  };

  return (
    <div className="location-search-wrapper">
      <div
        className="selected-location-wrapper"
        ref={locationSearchButton}
        onClick={() => setOpen(true)}
      >
        <SearchLocationIcon
          className="location-icon"
          fill={getLocationIconColor()}
        />
        <Tooltip
          arrow
          backgroundColor={COLOR_WHITE}
          color={secondaryTextColor}
          placement="top"
          title={locationText}
          classes={{ tooltip: "app-tooltip" }}
        >
          <div>
            <div className="deliver-heading-wrapper">
              <Typography
                className="selected-location-text"
                variant="h2"
                weight="semiBold"
                fontColor={headerColorConfig.textColor}
              >
                {selectedType === "pickup"
                  ? t("header.pickupFrom")
                  : t("header.deliverTo")}
              </Typography>
              <DownArrowIcon
                fill={
                  isDarkHeader
                    ? headerColorConfig.textColor
                    : headerColorConfig.iconColor
                }
                className="down-arrow-icon"
              />
            </div>
            <Typography
              className="selected-location-text location-value"
              variant="h3"
              weight="regular"
              fontColor={headerColorConfig.textColor}
            >
              {locationText}
            </Typography>
          </div>
        </Tooltip>
        <ChevronGroupIcon
          fill={headerColorConfig.iconColor}
          className="chevron-icon"
        />
      </div>
      <Dialog
        // searchButtonLeft is initially 0 before layout measure happens. This ensure
        // the modal does not show up on the left and move to the correct position
        // when it's shown when user opens for the first time.
        open={open && locationSearchButtonLeft > 0}
        keepMounted={!isMobileView}
        onClose={handleClose}
        ref={locationSearchDialog}
        TransitionComponent={Transition}
        disableScrollLock={true}
        classes={{
          root: "location-search-dialog-root",
          container: "location-search-dialog-container",
          paper: "location-search-dialog-paper",
        }}
      >
        {dialogState === "search" && (
          <SearchCard
            headerColorConfig={headerColorConfig}
            isDarkHeader={isDarkHeader}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            addresses={addresses}
            places={places}
            handleClose={handleClose}
            fullfilmentType={selectedType}
            setOpen={setOpen}
            fulfilmentCallback={fulfilmentCallback}
          >
            <div className="search-dialog-header">
              <TextField
                fullwidth="true"
                variant="filled"
                placeholder={t("searchLocator.searchBarStorePlaceholder")}
                onChange={handleOnChange}
                value={searchQuery}
                onFocus={handleOnFocus}
                onBlur={handleOnBlur}
                InputProps={{
                  autoFocus: locationSearchAutoFocus,
                  disableUnderline: true,
                }}
                classes={{ root: "location-search-box" }}
              />
            </div>
          </SearchCard>
        )}
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    config: state.config,
    upsdk: state.upsdk,
    showLocationDialog: state.ui.showLocationDialog,
    subLocality: state.ui.subLocality,
    selectedStore: upsdkSelector.getStore(state),
    selectedType: upsdkSelector.getFullfilment(state),
    cart: upsdkSelector.getCart(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setSubLocality: setSubLocality,
      setLocationDialog: setLocationDialog,
      unSetLocationDialog: unSetLocationDialog,
    },
    dispatch
  );

export default withTranslation(
  ["translations"],
  translateOptions
)(connect(mapStateToProps, mapDispatchToProps)(LocationSearch));

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
